import gsap from "gsap";
import {getRect} from "js/libs/util";
import assign from "lodash/assign";
import log from "js/main/log";

/*
---------------------
 Dropdown Structure:
---------------------
 container
   .title
   .wrapper
     .content
---------------------
*/

let openDropdown = null;

/* check if one DOM-element is parent of another one */
const isParent = function(parent, element) {
  if (!parent || !element) {
    return null;
  }

  while(element && element !== parent && element !== document.body) {
    element = element.parentElement;
  }

  return element === parent;
}

/* Add global eventlistener to check click on dropdown */
window.addEventListener("click", function(event) {
  if (openDropdown && !isParent(openDropdown.container, event.target)) {
    openDropdown.close();
    if (event.target)
      event.target.focus();
  }
}, true)

export default class Dropdown {
  constructor(container, init, options) {
    if(!container.classList.contains("dropdown")) {
      return log.error(container, "is not a dropdown Element. Aborting!");
    }
    this.container= container;
    this.options= assign({
      animate: true,
      transitionTime: 0.3,
      fixed: false,
    }, options);
    this.title = container.querySelectorAll(":scope > .title")[0];
    this.wrapper = container.querySelectorAll(":scope > .wrapper")[0];
    this.content = this.wrapper.querySelectorAll(":scope > .content")[0];
    this.t = this.options.animate ? this.options.transitionTime : 0;
    init(this)
  }

  /* Position content wrapper horizontally */
  position(){
    gsap.to(this.wrapper, {left: 0, duration: 0});

    const titleRect = getRect(this.title),
      wrapperRect = getRect(this.wrapper),
      preferredLeft = (titleRect.width - wrapperRect.width) / 2,
      leftDist = wrapperRect.left + preferredLeft,
      rightDist = wrapperRect.right - preferredLeft,
      left = preferredLeft - Math.min(leftDist, 0) + Math.min(rightDist, 0),
      maxHeight = (window.innerHeight - wrapperRect.top) + "px";
    
    // console.log("preferredLeft", preferredLeft, "leftDist", leftDist, "rightDist", rightDist, "left", left, "maxHeight", wrapperRect.top, window.innerWidth);
    
    if (this.options.fixed && window.innerWidth<=768){
      gsap.to(this.wrapper, {maxHeight, duration: 0});
    }
    gsap.to(this.wrapper, {left, duration: 0});
    gsap.to(this.content, {yPercent: -100, duration: 0});
  }

  open(){
    if(!this.container.classList.contains("open")){
      this.position();
      this.container.classList.add("open");
      if(openDropdown !== null && openDropdown !== this){
        openDropdown.close();
      }
      openDropdown= this;
      gsap.to(this.content, {yPercent: 0, duration: this.t});
      if(this.options.onTrigger)
        this.options.onTrigger("open");
    }
  }

  close(){
    if(this.container.classList.contains("open")){
      gsap.to(this.content, {yPercent: -100, duration: this.t, onComplete: function(){
        this.container.classList.remove("open");
      }.bind(this)});

      if(this === openDropdown){
        openDropdown= null;
      }
      if(this.options.onTrigger)
        this.options.onTrigger("close");
    }
  }

  toggle(){
    if(this.container.classList.contains("open")){
      this.close();
    }else{
      this.open();
    }
  }
}