import "less/views/widgets/spinner";
import React from "react";

class Spinner extends React.Component {
  render() {
    let props = [];
    if (this.props.immediate)
      props.push("immediate");

    if (this.props.inline)
      props.push("inline");

    return <div className={`spinner ${props.join(" ")}`}>
{/*      <div className="cube1"></div>
      <div className="cube2"></div>*/}
    </div>
  }
}

export default Spinner;
