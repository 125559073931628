import React from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";

export default class MarkdownComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const html = this.props.markdown ? marked(this.props.markdown) : "";
    const className = "markdown " + (this.props.className || "");
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: html }}
      ></div>
    );
  }
}
