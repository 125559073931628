import { initAnalytics, reportAnalytics } from "./analytics";
import { initPiwik, reportPiwik } from "./piwik";
import { initSentry } from "js/libs/sentry";
import log from "js/main/log";

type TrackingTag = string;
export const TagGoogleAnalytics: TrackingTag = "googleAnalytics";
export const TagGoogleMaps: TrackingTag = "googleMaps";
export const TagMapBox: TrackingTag = "mapBox";
export const TagYoutube: TrackingTag = "youtube";
export const TagPiwik: TrackingTag = "piwik";
export const TagSentry: TrackingTag = "sentry";

export const trackingAccepted = (tag: TrackingTag) => {
  // @ts-ignore
  return window.ccLoadedTags && window.ccLoadedTags.includes(tag);
};

class Tracker {
  init() {
    // @ts-ignore
    if (!window.ccLoadedTags) {
      setTimeout(() => this.init(), 5000);
      return;
    }
    log("tracking init");
    if (trackingAccepted(TagGoogleAnalytics)) initAnalytics();
    if (trackingAccepted(TagPiwik)) initPiwik();
    if (trackingAccepted(TagSentry)) initSentry();
  }

  // analytics & piwik
  pageView(url: string) {
    log("tracking pageview", url);
    if (url) {
      reportPiwik("setCustomUrl", url);
      reportAnalytics("set", "location", url);
    }

    reportPiwik("trackPageView");
    reportAnalytics("send", "pageview");
  }

  // piwik only
  login(type, id) {
    reportPiwik("setUserId", type + ":" + id);
    reportPiwik("trackEvent", "Actions", "Login", "Type", type);
  }

  logout(type) {
    reportPiwik("trackEvent", "Actions", "Logout", "Type", type);
  }

  // analytics only
  booking(id: string, revenue) {
    reportAnalytics("require", "ecommerce");
    reportAnalytics("ecommerce:addTransaction", { id, revenue });
    reportAnalytics("ecommerce:send");
  }
}

export default new Tracker();
