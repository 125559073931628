/**
 *  Google Maps Javascript-API wrapper
 *
 *  Loads google-maps API when window.onGMapsLoad is executed
 */
import wrapScript from "js/libs/wrapScript";
import {promise} from "js/libs/util";
import {serialize} from "js/libs/url";
import localeStore from "js/stores/localeStore";
import config from "js/main/config"

export default promise(function(fulfill){
  /* eslint-disable no-undef */
  window.onGMapsLoad= function(){
    fulfill(google.maps);
    delete window.onGMapsLoad;
  };
  /* eslint-disable no-undef */

  const params = {
    libraries: "places",
    callback: "onGMapsLoad",
    language: localeStore.lang,
    key: config.googleAPIKey,
    v: "3.39"
  };
  const url = "//maps.googleapis.com/maps/api/js?" + serialize(params);
  wrapScript(url);
});
