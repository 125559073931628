import React from "react";
import ReactDOM from "react-dom";
import Opentip from "js/libs/opentip";
import isMobile from "js/libs/isMobile";

Opentip.styles.default = {
  borderRadius: 0,
  background: "#fff",
  borderColor: "#0E71B8",
  target: true,
  stem: true,
  showOn: isMobile.any ? "click" : "mouseover",
  hideOn: isMobile.any ? "click" : null,
  tipJoint: "bottom",
  removeElementsOnHide: true
};

Opentip.styles.modal = {
  extends: "default",
  target: false
};

export default class extends React.Component {
  componentDidMount() {
    const content = this.props.content || "";
    const element = ReactDOM.findDOMNode(this.refs.tooltip);

    const tip = new Opentip(element, content, {
      style: this.props.style || "default"
    });
  }

  render() {
    return <div className={"tooltip" + (this.props.className ? " " + this.props.className : "")} ref="tooltip" title="">
      {this.props.children || null}
      <span className="icon icon-info-circled"></span>
    </div>
  }
}
