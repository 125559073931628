import bindAll from "lodash/bindAll";
import {getNearestCity} from "js/libs/geo";
import EventStore from "js/stores/EventStore";
import actions from "js/main/actions";
import log from "js/main/log";

export const SORTING = {
  DEFAULT: "encrypt",
  RATING: "rating",
  DISTANCE: "distance",
  PRICE: "price"
}

// get pseudo-random session sorting Salt
let storedSalt = sessionStorage.getItem("sortingSalt");
export const sortingSalt = storedSalt || String(Math.random() * Number.MAX_SAFE_INTEGER);

if (!storedSalt && Modernizr.sessionstorage)
  sessionStorage.setItem("sortingSalt", sortingSalt);

class UiStore extends EventStore {
  constructor() {
    super();
    this.features = [];
    this.categories = [];
    this.address = null;
    this.city = null;

    // filters
    this.from = null;
    this.to = null;
    this.query = null;
    this.adults = 1;
    this.children = 0;
    this.sortBy = SORTING.DEFAULT;
    this.page = 0;

    // map
    this.bounds = null;
    this.location = null;

    bindAll(this);
    this.listenToMany(actions, [
      "setTo",
      "setFrom",
      "setQuery",
      "resetDate",
      "setPersons",
      "selectCity",
      "changeSorting",
      "addressSelected",
      "mapMoved",
      "initStore",
      "updatePage"
    ]);
    this.listenTo(actions, "addFeature", this.addFilter.bind(this, "features"))
    this.listenTo(actions, "removeFeature", this.removeFilter.bind(this, "features"))
    this.listenTo(actions, "addCategory", this.addFilter.bind(this, "categories"))
    this.listenTo(actions, "removeCategory", this.removeFilter.bind(this, "categories"))
  }

  listenToMany(target, array) {
    for(let i = 0; i < array.length; i++){
      this.listenTo(target, array[i], this[array[i]]);
    }
  }

  setFrom(date) {
    this.from = new Date(date);
    if(this.to === null || !this.from.isBefore(this.to)){
      this.to = new Date(this.from);
      this.to.setDate(this.from.getDate() + 1);
    }
    this.trigger("dateUpdated")
  }

  setTo(date) {
    this.to = new Date(date);
    if(this.from === null || !this.from.isBefore(this.to)){
      this.from = new Date(this.to);
      this.from.setDate(this.to.getDate() - 1);
    }
    this.trigger("dateUpdated");
  }

  setQuery(text) {
    this.query = text;
    this.trigger("queryUpdated");
  }

  resetDate() {
    this.from = null;
    this.to = null;
    this.trigger("dateUpdated");
  }

  setPersons(adults, children) {
    if (adults !== undefined) {
      this.adults = adults;
    }

    if (children !== undefined) {
      this.children = children;
    }
    this.trigger("personsUpdated");
  }

  selectCity(city) {
    if (!city)
      return
    this.city = city;
    this.bounds = {
      swlat: city.swLat,
      swlong: city.swLong,
      nolat: city.noLat,
      nolong: city.noLong
    };
    this.trigger("citySelected");
  }

  changeSorting(value) {
    this.sortBy = value;
    this.trigger("sortingUpdated");
  }

  // filter handlers (categories, features)
  addFilter(identifier, id) {
    log("addFilter", identifier, id)
    this[identifier].push(id);
    this.trigger("filterUpdated");
  }

  removeFilter(identifier, id) {
    let index = this[identifier].indexOf(id);
    if(index !== -1){
      this[identifier].splice(index, 1);
    }
    this.trigger("filterUpdated");
  }

  addressSelected(address, location, bounds) {
    this.address = address;
    this.location = location;
    if (bounds) {
      this.bounds = bounds;
    } else {
      this.bounds = this.estimateBounds();
    }

    this.changeSorting(SORTING.DISTANCE);
    this.trigger("addressSelected");
  }

  // set city to the closest one
  updateCity(bounds) {
    // don't automatically select city if we are in free search mode
    if (this.city && this.city.id === -1)
      return;

    const center = {
      lat: bounds.swlat + (bounds.nolat - bounds.swlat) / 2,
      long: bounds.swlong + (bounds.nolong - bounds.swlong) / 2
    }

    const city = getNearestCity(center);
    if (city && (!this.city || city.id !== this.city.id)) {
      this.city = city;
      this.trigger("citySelected");
    }
  }

  updatePage(page) {
    this.page = page;
    this.trigger("pageUpdated");
  }

  mapMoved(bounds) {
    log("mapmoved");
    this.bounds = bounds;
    this.trigger("mapMoved");
  }

  estimateBounds() {
    const pixelFactor = 3 / 1000,
      mapWidthGuess = Math.max(window.innerWidth, 1) * pixelFactor,
      mapHeightGuess = Math.max(window.innerHeight - 310, 1) * pixelFactor,
      location = this.location;

    return {
      swlat: location.lat - mapHeightGuess * pixelFactor,
      swlong: location.long - mapWidthGuess * pixelFactor * 2,
      nolat: location.lat + mapHeightGuess * pixelFactor,
      nolong: location.long + mapWidthGuess * pixelFactor * 2
    };
  }

  // must be called before fetching anything
  initialize(values) {
    super.initialize(values);

    // initialize with bounds
    if (values.swlat && values.swlong && values.nolat && values.nolong) {
      this.bounds = {
        swlat: values.swlat,
        swlong: values.swlong,
        nolat: values.nolat,
        nolong: values.nolong
      };
    }

    // initialize with location
    if (values.locationLat && values.locationLong) {
      this.location = {
        lat: values.locationLat,
        long: values.locationLong
      };

      this.changeSorting(SORTING.DISTANCE);

      // estimate bounds from location for initial fetch
      if (!this.bounds) {
        this.bounds = this.estimateBounds();
        this.earlyBounds = 1;
      }
    }

    log("uiStore init - location:", this.location, "bounds: ", this.bounds, "earlyBounds:", this.earlyBounds);

    if (this.bounds) {
      // set default city if we are in free search mode
      if (values.cityset === 0) {
        this.city = init.cities[0];

      // determine city next to the viewport
      } else {
        this.updateCity(this.bounds);
      }

    // if we have neither bounds nor location
    } else {
      const city = init.cities[0];
      const cityBounds = {
        swlat: city.swLat,
        swlong: city.swLong,
        nolat: city.noLat,
        nolong: city.noLong
      }

      // this is a bit ugly
      this.selectCity(city);
      this.updateCity(cityBounds);
    }
  }
}

let uiStore = new UiStore();
export default uiStore;
