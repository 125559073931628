import find from "lodash/find";
import reduce from "lodash/reduce";
import filter from "lodash/filter";
import cloneDeep from "lodash/cloneDeep";
import Backbone from "js/libs/Backbone";
import ItemStore from "js/libs/ItemStore";
import {serialize, escape} from "js/libs/url";
import {featureChargeable, nullZero} from "js/libs/util";
import favoriteStore from "js/stores/favoriteStore";
import config from "js/main/config";
import locale from "js/main/locale";
import log from "js/main/log";

/* RoomtypeStore */
export default ItemStore.extend({
  defaults: {
    address: {},
    category: {},
    price: {options: [], features: []},
    rating: {},
    features: [],
  },
  url: function() {
    return config.url(`roomtype/${this.id}`);
  },
  constructor: function(attributes, options) {
    Backbone.Model.apply(this, arguments);
    this.listenTo(favoriteStore, "add remove reset", this.checkFavorite.bind(this));

    if (options.remote) {
      this.id = options.roomtypeId;

      if (options.authenticate) {
        this.authenticate = options.authenticate;
      }

      this.update({reset: true});
    }

    if (options.display) {
      this.display = options.display;
    }
  },
  parse: function(item) {
    item.isFavorite = favoriteStore.get(item.id) ? true : false;
    return item;
  },
  checkFavorite: function() {
    const isFavorite = favoriteStore.get(this.id) ? true : false
    this.set("isFavorite", isFavorite);
  },
  // TODO: use a linkbuilder from the router
  getURL: function(params={}) {
    const address = this.get("address");

    if (!address || !address.city)
      throw new Error("Error: getURL needs roomtype with address!");

    const city = address && address.city ? escape(address.city.toLowerCase().replace(/( |\/)/g, "_")) : "",
      type = this.get("category").id == 1 ? "gästezimmer" : "ferienwohnung";

    return `/${city}/unterkunft/${this.id}/${type}?${serialize(params)}`;
  },
  getCategory: function() {
    if (this.has("category"))
      return find(init.categories, {"id": this.get("category").id})

    return null;
  },
  _getFeaturesInGroup: function(featureGroup, featureIds) {
    const group = featureGroup.features.map((feature) => feature.id);
    const features = [];
    for (let i = 0; i < featureIds.length; i++) {
      const index = group.indexOf(featureIds[i]);
      if(index !== -1){
        const feature = featureGroup.features[index];
        features.push(feature);
      }
    }
    return features;
  },
  getFeatureGroups: function() {
    const featureGroups = cloneDeep(init.featureGroups);
    const features = this.get("features");
    for (let i = 0; i < featureGroups.length; i++) {
      featureGroups[i].features = this._getFeaturesInGroup(featureGroups[i], features);
    }
    return featureGroups;
  },
  getFiles: function(filterfunc = (f) => true) {
    return filter(this.get("files"), filterfunc);
  },
  _mapFilesToSliderFormat: function(filterfunc, attr) {
    const altTitle = `${this.get("name")} - ${this.get("subtitle")}`
    return this.getFiles(filterfunc).map((image) => ({
      id: image.id,
      href: config.imageUrl(image[attr]),
      title: image.title || altTitle
    }));
  },
  getSliderImages: function(sliderOnly = true, attr = "url") {
    return this._mapFilesToSliderFormat((f) => f.type === "image" &&
        (!sliderOnly || f.slider), attr);
  },
  getGalleryImages: function() {
    return this.getFiles((f) => f.type === "image" && !f.slider, "thumbnail");
  },
  getPanoramas: function() {
    return this.getFiles((f) => f.type === "panorama");
  },
  getVideos: function() {
    return this.getFiles((f) => f.type === "video");
  },
  getChargeableFeatures: function() {
    const price = this.get("price");
    return filter(price.features, (feature) => featureChargeable(feature.id));
  },
  getShortTimeCharge: function(days) {
    if (this.has("minDays") && days < this.get("minDays")) {
      return init.config.minDaysSurcharge;
    } else {
      return 0;
    }
  },
  getFeatureTotal: function(selectedFeatures) {
    const features = this.get("price").features;
    const prices = selectedFeatures.map((id) => {
      const feature = find(features, {"id": id});
      return feature ? feature.total : 0;
    });
    return reduce(prices, (total, value) => total + value, 0);
  },
  // "pure" overnight Price
  getOvernightTotal: function(days) {
    const extensionCredit = this.get("price").extensionOptionCreditNote || 0;
    return this.get("price").total + this.get("price").discount - this.getShortTimeCharge(days) +
      extensionCredit;
  },
  getCleaningPrice: function() {
    return nullZero(this.get("price").totalCleaning);
  },
  getFeaturePrice: function() {
    return nullZero(this.get("price").featureTotal);
  },
  hasTotalPrice: function() {
    return !!this.get("price").totalIncCleaning;
  },
  // Total Price
  //   selectedFeatures - add Prices of selected features
  //   hasExtensionOption - Calculate with price of extension option
  //   purePrice - without cleaning and discounts
  getTotalPrice: function(selectedFeatures, hasExtensionOption, purePrice) {
    const extensionPrice = hasExtensionOption ? this.get("price").extensionOption : 0;
    const pureDifference = purePrice ? this.get("price").discount - this.getCleaningPrice() : 0;
    return this.get("price").totalIncCleaning + pureDifference + extensionPrice + this.getFeatureTotal(selectedFeatures);
  },
  getParams: function() {
    return {
      display: this.display || ["ALL"],
      lang: locale.lang
    };
  },
});
