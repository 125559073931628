// setup Backbone addons
import isFunction from "lodash/isFunction";
import Backbone from "backbone";
import NativeAjax from "js/libs/NativeAjax";
import LocalStorage from "./backbone.localstorage";
Backbone.ajax = NativeAjax;
Backbone.LocalStorage = LocalStorage;

// replace Backbones sync because backbone.localstorage is a stupid module
Backbone.ajaxSync = Backbone.sync;
Backbone.localSync = Backbone.LocalStorage.sync;

function result(object, property) {
  if (object === undefined) {
    return void 0;
  }

  let value = object[property];
  return (isFunction(value)) ? object[property]() : value;
}

Backbone.getSyncMethod = function(model, options) {
  let forceAjaxSync = options && options.ajaxSync;
  if (model && !forceAjaxSync && (result(model, 'localStorage') || result(model.collection, 'localStorage'))) {
    return Backbone.localSync;
  }
  return Backbone.ajaxSync;
};

// Override 'Backbone.sync' to default to localSync,
// the original 'Backbone.sync' is still available in 'Backbone.ajaxSync'
Backbone.sync = function(method, model, options) {
  return Backbone.getSyncMethod(model, options).apply(this, [method, model, options]);
};

export default Backbone