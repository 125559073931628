import assign from "lodash/assign";
import isFunction from "lodash/isFunction";
import React from "react";
import Backbone from "js/libs/Backbone";
import log from "js/main/log";

/* Standard React Backbone Mixin */
export default {
  getInitialState: function () {
    return isFunction(this.getBackboneState) ? this.getBackboneState(this.props) : {};
  },

  componentDidMount: function () {
    if (!isFunction(this.getBackboneState)) {
      this.getBackboneState= function(){
        return {};
      }
    }

    this._bindBackboneEvents(this.props);
  },

  componentWillReceiveProps: function (newProps) {
    this._unbindBackboneEvents();
    this._bindBackboneEvents(newProps);
  },

  componentWillUnmount: function () {
    this._unbindBackboneEvents();
  },

  updateBackboneState: function () {
    let state= this.getBackboneState(this.props);
    this.setState(state);
  },

  _bindBackboneEvents: function (props) {
    if (!isFunction(this.watchBackboneProps)) {
      return;
    }

    if (this._backboneListener) {
      throw new Error('Listener already exists.');
    }

    if (!props) {
      throw new Error('Passed props are empty');
    }

    let listener = assign({}, Backbone.Events),
    listenTo = (obj, name, callback) => listener.listenTo(obj, name, callback || this.updateBackboneState);
    // _.partialRight(listener.listenTo.bind(listener), this._updateBackboneState);
    this.watchBackboneProps(props, listenTo);
    this._backboneListener = listener;
  },

  _unbindBackboneEvents: function () {
    if (!isFunction(this.watchBackboneProps)) {
      return;
    }

    if (!this._backboneListener) {
      log.info("Backbone Listener already unbound!");
      return;
    }

    this._backboneListener.stopListening();
    delete this._backboneListener;
  }
};

/* ES6 Backbone Listening Component Wrapper */
export function getStoreConnection(Component){
  class StoreConnection extends React.Component{
    // interface
    getStores(props){}
    watchStores(stores, listenTo){}
    getStoreState(stores){return {}}
    shouldStoresUpdate(props){return true}

    // State Management
    _updateState(){
      this.setState(this.getStoreState(this.stores));
    }

    _bindEvents(){
      this.listener = assign({}, Backbone.Events);
      const listenTo= (obj, name, callback) => this.listener.listenTo(obj, name, callback || this._updateState.bind(this));
      this.watchStores(this.stores, listenTo);
    }

    _unbindEvents(){
      if(this.listener)
        this.listener.stopListening();
    }

    // Lifecycle Methods
    constructor(props){
      super();
      this.stores = this.getStores(props);
      let state = {};
      if (this.getState)
        state = this.getState();

      if (this.getStoreState)
        assign(state, this.getStoreState(this.stores));

      this.state = state;
    }

    componentDidMount(){
      this._bindEvents();
    }

    componentWillUnmount(){
      this._unbindEvents();
    }

    componentWillReceiveProps(newProps){
      if (this.shouldStoresUpdate(newProps)) {
        this._unbindEvents();
        this.store= this.getStores(newProps);
        this._bindEvents();
      }
    }

    render(){
      return <Component {...this.props} {...this.state} />;
    }
  }

  return StoreConnection;
}
