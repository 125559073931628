import Backbone from "js/libs/Backbone";
import assign from "lodash/assign";
import defer from "lodash/defer";
import noop from "lodash/noop";
import isFunction from "lodash/isFunction";
import guestStore from "js/stores/guestStore";
import hostStore from "js/stores/hostStore";
import {redirectUnauthorized} from "js/libs/url";
import log from "js/main/log";

/* ItemStore */
export default Backbone.Model.extend({
  loaded: false,
  busy: false,
  invalid: false,
  updateOptions: {},
  getParams: () => {return {}},

  // ajax method which prevents a new request if a response is still pending
  update: function(options) {
    if(!this.busy){
      this.busy= true;
      this.invalid= false;

      let headers= {};
      if(this.authenticate){
        let token= this.authenticate === "guest" ? guestStore.get("token") : hostStore.get("token");
        if(token){
          headers["X-Nd-Token"]= token;
        }else{
          redirectUnauthorized(this.authenticate);
          return;
          // throw new Error("Not logged in, redirect to seperate page?")
        }
      }

      let params = this.getParams ? this.getParams(options) : {};
      this.fetch(assign({
        data: params,
        context: this,
        headers: headers,
        success: function(model, response){
          log.info("fetch success", typeof this.url == "function" ? this.url() : this.url, response);
          this.status = 200;
          this.loaded = true;
          this.busy = false;
          if (this.invalid) {
            this.update(); // hope this doesn't loop too often
          }
        }.bind(this),
        error: function(model, xhr){
          if(xhr.status === 401 && this.authenticate){
            redirectUnauthorized(this.authenticate);
            return;
          }
          this.status = xhr.status;
          log.error("fetch error:", xhr.status, xhr.statusText, `"${xhr.response}"`, xhr.responseURL);
          this.trigger("error");
          this.busy = false;
          if(this.invalid){
            this.update(); // hope this doesn't loop too often
          }
        }.bind(this),
        remove: false,
        merge: false,
      }, this.updateOptions, options));
    }else{
      this.invalid= true;
    }
  },
  deferredUpdate: function(options) {
    defer(this.update, options);
  }
});
