import assign from "lodash/assign";
import Backbone from "js/libs/Backbone";

function EventStore(){}
assign(EventStore.prototype, Backbone.Events, {
  listenToMany(target, array) {
    for(let i = 0; i < array.length; i++){
      this.listenTo(target, array[i], this[array[i]]);
    }
  },
  initialize(values) {
    for (let key in values) {
      if(Object.prototype.hasOwnProperty.call(this, key)){
        this[key] = values[key];
      }
    }
    this.trigger("init");
  }
});

export default EventStore;
