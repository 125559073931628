import "less/views/widgets/confirmModal";
import {useState} from "react";
import locale from "js/main/locale";
import Modal from "react-modal";
import Markdown from "js/views/widgets/Markdown";

export interface ConfirmModalProps {
  msg: string;
  title?: string;
  okText?: string;
  cancelText?: string;
  markdown?: boolean;
  noCancel?: boolean;
  callback: (ok: boolean) => void;
};

/* ConfirmModal */
export default function ConfirmModal(props: ConfirmModalProps) {
  const [isOpen, setOpen] = useState(true);
  const handleConfirm = () => {
    setOpen(false);
    props.callback(true);
  };

  const handleCancel = () => {
    setOpen(false);
    props.callback(false);
  };

  const title = props.title || "",
    msg = props.msg || "",
    okText = props.okText || locale.ok,
    cancelText = props.cancelText || locale.cancel;

  return (
    <Modal
      className="confirmModal"
      isOpen={isOpen}
      onRequestClose={handleCancel}
    >
      <h3 className="title">{title}</h3>
      {props.markdown ? (
        <Markdown markdown={msg} className="msg" />
      ) : (
        <div className="msg">{msg}</div>
      )}
      <div className="btns">
        <button
          className="btn btn-secondary confirmBtn"
          onClick={handleConfirm}
        >
          {okText}
        </button>
        {!props.noCancel ? (
          <button
            className="btn btn-tertiary cancelBtn"
            onClick={handleCancel}
          >
            {cancelText}
          </button>
        ) : (
          ""
        )}
      </div>
    </Modal>
  );
}
