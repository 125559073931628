import once from "lodash/once";
import spread from "lodash/spread";
import throttle from "lodash/throttle";
import gmaps from "js/libs/gmaps";
import log from "js/main/log";
const meterToDegree= 1 / (111 * 1000);

const getDistance= exports.getDistance= function(pos1, pos2){
  if (!pos1 || !pos2)
    return Number.MAX_VALUE;

  return Math.sqrt(Math.pow(pos1.lat - pos2.lat, 2) + Math.pow(pos1.long - pos2.long, 2));
};

export const getNearestCity = function(pos){
  if (!pos.lat || !pos.long)
    return null;

  let distances = [];
  init.cities.forEach((city) => distances.push(getDistance(city.center, pos)))
  let index = distances.indexOf(spread(Math.min)(distances));
  return init.cities[index];
};

const seededRandom = function(seed) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

export const scramblePosition = function(id, lat, long){
  let distance = 100 * meterToDegree * seededRandom(id);
  let angle = 2 * Math.PI * seededRandom(id * 2);
  return {
    lat: lat + Math.cos(angle) * distance,
    long: long + Math.sin(angle) * distance
  };
};

export const gToPlainBounds = function(bounds) {
  if (!bounds)
    return null;

  const sw = bounds.getSouthWest();
  const no = bounds.getNorthEast();
  return {
    swlat: sw.lat(),
    swlong: sw.lng(),
    nolat: no.lat(),
    nolong: no.lng()
  }
}

export const gToPlainLocation = function(location) {
  if (!location)
    return null;

  return {
    lat: location.lat(),
    long: location.lng()
  }
}


// src: http://stackoverflow.com/a/13274361/1078831
// calculates required google maps zoom for given bounds
export const getRequiredZoom = function(bounds, mapDim) {
  const WORLD_DIM = { width: 256, height: 256 };
  const ZOOM_MAX = 18;

  const latRad = function(lat){
    const sin = Math.sin(lat * Math.PI / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  const zoom = function(mapPx, worldPx, fraction){
    // offset -1
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2) - 1;
  }

  const latFraction= (latRad(bounds.nolat) - latRad(bounds.swlat)) / Math.PI,
  lngDiff= bounds.nolong - bounds.swlong,
  lngFraction= ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

  const latZoom= zoom(mapDim.height, WORLD_DIM.height, latFraction) || ZOOM_MAX;
  const lngZoom= zoom(mapDim.width, WORLD_DIM.width, lngFraction) || ZOOM_MAX;
  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

export const findAddress = (function(){
  let busy= false,
    dirty= false,
    coder;

  const init= once(function(callback){
    gmaps(function(maps){
      coder= new maps.Geocoder();
      callback(coder);
    })
  });

  const getGeocoder= function(callback){
    if(coder)
      callback(coder)
    else
      init((geocoder) => callback(geocoder));
  }

  const findGeocoderAddress= throttle(function(street, number, postcode, city, country, callback){
    // treat foostr. as foostrasse
    if (street)
      street = street.replace("str.", "strasse");

    log('findAddress', street, number, postcode, city, country);
    getGeocoder(function(geocoder){
      if(!street || !number || !postcode || !city)
        return;

      if(busy){
        dirty= true;
        return;
      }

      busy= true;
      const params= {address: `${street} ${number}, ${postcode} ${city}, ${country}`};
      geocoder.geocode(params, function(result){
        log("geocoder result", params, result);
        if(result === null)
          return callback();

        let entry;
        result.forEach(function(item){
          if(!item.partial_match || result.length == 1)
            entry= item;
        });

        if(entry)
          callback(entry.geometry.location.lat(), entry.geometry.location.lng());
        else
          callback();

        if(dirty){
          findGeocoderAddress.apply(this, arguments);
        }else{
          busy= false;
        }
      });
    })
  }, 1000);

  return findGeocoderAddress;
})();

export const ndCountries= ["DE", "CH", "AT", "NL","ES", "PT", "GR"];

// http://datahub.io/de/dataset/iso-3166-1-alpha-2-country-codes/
export const countries= [
  "Afghanestan",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Hayastan",
  "Australia",
  "Österreich",
  "Azarbaycan Respublikasi",
  "The Bahamas",
  "Al Bahrayn",
  "Bangladesh",
  "Barbados",
  "Byelarus",
  "Belgique",
  "Belice",
  "Benin",
  "Drukyul",
  "Bolivia",
  "Bosna i Hercegovina",
  "Botswana",
  "Brasil",
  "Brunei",
  "Republika Bulgariya",
  "Burkina Faso",
  "Burundi",
  "Kampuchea",
  "Cameroun",
  "Canada",
  "Cabo Verde",
  "Republique Centrafricaine",
  "Tchad",
  "Chile",
  "Zhong Guo",
  "Colombia",
  "Comores",
  "Republique du Congo",
  "Costa Rica",
  "Cote d'Ivoire",
  "Hrvatska",
  "Cuba",
  "Kypros",
  "Ceska Republika",
  "Danmark",
  "Djibouti",
  "Dominica",
  "Republica Dominicana",
  "Ecuador",
  "Misr",
  "El Salvador",
  "Guinea Ecuatorial",
  "Ertra",
  "Eesti",
  "Fiji",
  "Suomi",
  "France",
  "Gabon",
  "The Gambia",
  "Deutschland",
  "Ghana",
  "Ellas",
  "Grenada",
  "Guatemala",
  "Guinee",
  "Guine-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Magyarorszag",
  "Island",
  "India, Bharat",
  "Indonesia",
  "Iran, Persia",
  "Al Iraq",
  "Ireland",
  "Yisra'el",
  "Italia",
  "Jamaica",
  "Nippon",
  "Al Urdun",
  "Qazaqstan",
  "Kenya",
  "Kiribati",
  "Taehan-min'guk",
  "Al Kuwayt",
  "Latvija",
  "Lubnan",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lietuva",
  "Luxembourg",
  "Makedonija",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Muritaniyah",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongol Uls",
  "Al Maghrib",
  "Mocambique",
  "Namibia",
  "Nauru",
  "Nepal",
  "Nederland",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norge",
  "Uman",
  "Pakistan",
  "Belau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Pilipinas",
  "Polska",
  "Portugal",
  "Qatar",
  "Romania",
  "Rossiya",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Samoa",
  "San Marino",
  "Sao Tome e Principe",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovensko",
  "Slovenija",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "Espana",
  "Sri Lanka",
  "As-Sudan",
  "Suriname",
  "Swaziland",
  "Sverige",
  "Schweiz",
  "Suriyah",
  "T'ai-wan",
  "Jumhurii Tojikistan",
  "Tanzania",
  "Muang Thai",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunis",
  "Turkiye",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukrayina",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekiston Respublikasi",
  "Vanuatu",
  "Santa Sede (Citta del Vaticano)",
  "Venezuela",
  "Viet Nam",
  "Al Yaman",
  "Zambia",
  "Zimbabwe"
];
