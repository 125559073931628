import Backbone from "js/libs/Backbone";
import { NavigateOptions } from "backbone";

export interface ModalOptions {
  title?: string;
  okText?: string;
  cancelText?: string;
  markdown?: boolean;
  noCancel?: boolean;
  callback?: (ok: boolean) => void;
}

// typed events
class Actions implements Backbone.Events {
  on: Backbone.Events_On<Actions>;
  off: Backbone.Events_Off<Actions>;
  trigger: Backbone.Events_Trigger<Actions>;
  bind: Backbone.Events_On<Actions>;
  unbind: Backbone.Events_Off<Actions>;

  once: Backbone.Events_On<Actions>;
  listenTo: Backbone.Events_Listen<Actions>;
  listenToOnce: Backbone.Events_Listen<Actions>;
  stopListening: Backbone.Events_Stop<Actions>;

  // Navigate the page
  navigate(fragment: string, options?: NavigateOptions | boolean) {
    this.trigger("navigate", fragment, options);
  }

  // Show global modal
  showModal(msg: string, options: ModalOptions) {
    this.trigger("showModal", msg, options);
  }
}
Object.assign(Actions.prototype, Backbone.Events);

const actions = new Actions();
[
  // favorites
  "addFavorite",
  "replaceFavorites",
  "removeFavorite",
  "toggleFavorite",
  "clearFavorites",

  // search
  "addressSelected",

  // resultslider
  "resultSliderResized",
  "updatePage",
  "setResultCount",
  "favoriteAllResults",

  // dateinputs
  "setFrom", // (date: Date)
  "setTo", // (date: Date)
  "setQuery",
  "resetDate",

  // filter
  "addFeature",
  "removeFeature",
  "addCategory",
  "removeCategory",

  // person selects
  "setPersons", // (adults: int, children: int)

  // city select
  "selectCity",

  // sorting
  "changeSorting", // one of "rating", "price" and "distance"

  // map
  "mapMoved",
  "markerClicked",
  "markerHighlighted",
  "markerReset",
  "highlightMarker",
  "resetMarker",

  // details page extension option
  "setExtensionOption",

  // global Spinner
  "showSpinner",
  "hideSpinner",

  // auth
  "login", // (guest: Guest, token: String) or (host: Host, token: String)
  "logout",

  // language
  "changeLanguage",
].forEach((action) => {
  actions[action] = actions.trigger.bind(actions, action);
});

// actions.navigate = function(target, options) {
//   actions.trigger("navigate", target, options);
// }

export default actions;
