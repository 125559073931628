import "less/views/widgets/roomtypeLookupModal.less";
import React from "react";
import createReactClass from 'create-react-class';
import ReactDOM from "react-dom";
import Modal from "react-modal";
import throttle from "lodash/throttle";
import config from "js/main/config";
import locale from "js/main/locale";
import log from "js/main/log";
import ajax from "js/libs/NativeAjax";
import Tooltip from "js/views/widgets/Tooltip";
import Spinner from "js/views/widgets/Spinner";

const RoomtypeLookupModal = createReactClass({
  displayName: 'RoomtypeLookupModal',

  getInitialState: function() {
    this.busy = false;
    this.invalid = false;
    return {
      isOpen: true,
      id: "",
      validated: false,
      valid: false
    };
  },

  // focus upcoming input
  componentDidMount: function() {
    setTimeout(() => {
      const element = ReactDOM.findDOMNode(this.refs.input);
      element.focus();
    }, 100);
  },

  validate: throttle(function() {
    if (this.busy) {
      this.invalid = true;
      return;
    }

    if (!this.state.id || isNaN(parseInt(this.state.id))) {
      this.invalid = false;
      this.setState({validated: true, valid: false});
      return;
    }

    this.busy = true;
    ajax({
      url: config.url(`roomtype/${this.state.id}`),
      type: "GET",
      success: () => {

        this.busy = false;
        if (this.invalid)
          this.validate();
        else
          this.setState({validated: true, valid: true});
        this.invalid = false;
      },
      error: (xhr) => {
        this.busy = false;
        if (xhr.status !== 404 && xhr.status !== 400) {
          log.trace(`Response from ${xhr.responseURL}: ${xhr.status} ${xhr.statusText}, ${xhr.response}`)
        }

        if (this.invalid)
          this.validate();
        else
          this.setState({validated: true, valid: false});
        this.invalid = false;
      },
    });
  }, 100, {leading: false}),

  handleConfirm: function(event) {
    event.preventDefault();
    if (!this.state.validated || !this.state.valid)
      return;

    this.setState({isOpen: false});
    this.props.callback(true, {
      id: this.state.id
    });
  },

  handleCancel: function(event) {
    if (event)
      event.preventDefault();

    this.setState({isOpen: false});
    this.props.callback(false);
  },

  handleChange: function(event) {
    this.setState({validated: false, id: event.target.value});
    this.validate();
  },

  render: function() {
    let status = null;
    if (this.state.id) {
      if (this.state.validated) {
        status = this.state.valid ? locale.roomtypeExists : locale.roomtypeNotFound;
      } else {
        status = <Spinner inline={true}></Spinner>;
      }
    }

    return <Modal className="roomtypeModal" isOpen={this.state.isOpen}
      onRequestClose={this.handleCancel}>
      <form>
        <h3 className="title">{locale.enterRoomtypeNumber}</h3>
        <div className="content">
          <label className="form-group">
            <Tooltip style="modal" content={locale.roomtypeTooltip}>
              {locale.roomtypeNumber}
            </Tooltip>:
            <input type="text" onChange={this.handleChange} value={this.state.id}
             ref="input"/>
          </label>

          <div className="searchState">
            {status}
          </div>
        </div>

        <div className="btns">
          <button onClick={this.handleConfirm} className={"btn btn-secondary confirmBtn" +
              (this.state.validated && this.state.valid ? "" : " disabled")}>
            {locale.ok}
          </button>
          <button className="btn btn-tertiary cancelBtn"
           onClick={this.handleCancel}>
            {locale.cancel}
          </button>
        </div>
      </form>
    </Modal>
  },
})

export default RoomtypeLookupModal;