import "less/views/widgets/favoriteDropdown";
import find from "lodash/find";
import React from "react";
import createReactClass from 'create-react-class';
import ReactDOM from "react-dom";
import {serialize} from "js/libs/url";
import locale from "js/main/locale";
import config from "js/main/config";
import actions from "js/main/actions";
import uiStore from "js/stores/uiStore";
import favoriteStore from "js/stores/favoriteStore";
import BackboneMixin from "js/views/BackboneMixin";
import RoomtypeStore from "js/stores/RoomtypeStore";
import Dropdown from "./Dropdown";
import log from "../../main/log";

const FavoriteItem = createReactClass({
  displayName: 'FavoriteItem',
  mixins: [BackboneMixin],

  getBackboneState: function(props){
    return {
      item: props.item
    }
  },

  watchBackboneProps: function(props, listenTo){
    listenTo(props.item, "change");
  },

  shouldComponentUpdate: function(nextProps){
    return this.props.item.toJSON() !== nextProps.item.toJSON();
  },

  componentDidMount: function(){
    let removeBtn = ReactDOM.findDOMNode(this.refs.removeBtn);
    removeBtn.addEventListener("click", this.handleRemove);
  },

  componentWillUnmount: function(){
    let removeBtn = ReactDOM.findDOMNode(this.refs.removeBtn);
    removeBtn.removeEventListener("click", this.handleRemove);
  },

  handleRemove: function(event){
    event.preventDefault();
    event.stopPropagation();
    actions.removeFavorite(this.state.item);
  },

  render: function(){
    let properties = this.state.item.attributes;
    let image = find(properties.files, {type: "image"});

    const urlParams = {
      adults: uiStore.adults,
      children: uiStore.children,
    };

    if (uiStore.from && uiStore.to) {
      urlParams.from = uiStore.from;
      urlParams.to = uiStore.to;
    }

    let href = `/details/${properties.id}?${serialize(urlParams)}`

    if (properties.address && properties.address.city) {
      href = RoomtypeStore.prototype.getURL.call(this.props.item, urlParams);
    }

    return <li title={properties.name}>
      <a href={href}>
        <img className="thumb" alt={image ? image.title : ""}
            src={image ? config.imageUrl(image.thumbnail) : ""}/>
        <div className="text">{properties.name}</div>
        <div className="rmBtn icon icon-trash-empty" ref="removeBtn" title={locale.removeItem}></div>
      </a>
    </li>;
  },
});

/* FavoriteDropdown */
export default createReactClass({
  displayName: 'FavoriteDropdown',
  mixins: [BackboneMixin],

  watchBackboneProps: function(props, listenTo){
    listenTo(favoriteStore, "sync add remove reset");
    listenTo(favoriteStore, "add remove reset", this.doBlink);
  },

  getBackboneState: function(){
    return {
      favorites: favoriteStore.models,
      page: 0
    };
  },

  handleRemove: function(){
    actions.clearFavorites();
  },

  nextPage: function(){
    this.setState({page: this.state.page + 1});
  },

  prevPage: function(){
    this.setState({page: this.state.page - 1});
  },

  dropdownInit: function(that){
    let title= ReactDOM.findDOMNode(this.refs.title);
    title.addEventListener("click", () => that.toggle());
  },

  doBlink: function() {
    const dropdown = ReactDOM.findDOMNode(this.refs.dropdown);
    dropdown.classList.add("blinking");
    setTimeout(() => dropdown.classList.remove("blinking"), 200);
  },

  render: function() {
    const pageSize = 5;
    let items = this.state.favorites
    const maxPages = Math.ceil((items.length || 1) / pageSize);
    const page = Math.min(this.state.page, maxPages - 1);
    if (page !== this.state.page) {
      this.state.page= page;
    }

    items = items.slice(pageSize * page, pageSize * (page + 1));
    const pageString = `${page + 1}/${maxPages}`;
    const title = (
        <div className="title ubtn ubtn-light" tabIndex={0} ref="title" title={locale.manageFavorites}>
            <span>{locale.favorites}</span>
            <i className="icon icon-plus-circled" />
        </div>
    );

    const offerParams = {
      from: uiStore.from ? uiStore.from.toISODate() : null,
      to: uiStore.to ? uiStore.to.toISODate() : null,
      adults: uiStore.adults,
      children: uiStore.children
    };
    const content = [<ul key="list">
      {items.length ? "" : <div className="noFavorites">
      {locale.noFavorites1} <br/>
      <span className="icon icon-plus-circled"/>
      {locale.noFavorites2}
      </div>}
      {items.map((item) => <FavoriteItem key={item.id} item={item} />)}
    </ul>,
    <div key="menu" className="menu">
      <button className="rmAll" onClick={this.handleRemove} title="">
        <span className="icon icon-trash-empty"/>
        {locale.deleteAll}
      </button>
      <a className="offer" href={"/angebot?" + serialize(offerParams)} title="">
        <span className="icon icon-print"/>
        {locale.createOffer}
      </a>
      <div className="pages">
        <div onClick={this.prevPage} className={"icon icon-left-dir" + (page === 0 ? " hidden" : "")}/>
        <span>{pageString}</span>
        <div onClick={this.nextPage} className={"icon icon-right-dir" + (page === maxPages-1 ? " hidden" : "")}/>
      </div>
    </div>];

    return <Dropdown id="favoriteDropdown"
      title={title} content={content} fixed
      init={this.dropdownInit} ref="dropdown"/>;
  },
});
