import bindAll from "lodash/bindAll";
import Backbone from "js/libs/Backbone";
import guestStore from "js/stores/guestStore";
import actions from "js/main/actions";
import log from "js/main/log";

// Favorite Collection with Localstorage persistence
let FavoriteCollection = Backbone.Collection.extend({
  localStorage: new Backbone.LocalStorage("favoriteStore"),
  initialize: function() {
    bindAll(this);
    this.listenTo(actions, "addFavorite", this.addFavorite);
    this.listenTo(actions, "replaceFavorites", this.replaceFavorites);
    this.listenTo(actions, "removeFavorite", this.removeFavorite);
    this.listenTo(actions, "toggleFavorite", this.toggleFavorite);
    this.listenTo(actions, "clearFavorites", this.clearFavorites);
    this.listenTo(actions, "logout", this.handleLogout);
    this.fetch();

    this.listenTo(guestStore.favorites, "sync", this.updateGuestFavorites);
  },

  /* replace local favorites with online version */
  updateGuestFavorites: function(guestFavoriteStore, models) {
    log("using fetched guest favorites", arguments);

    const clonedList = this.models.slice();
    for (let i = 0; i < clonedList.length; i++) {
      clonedList[i].destroy();
    }
    this.reset(models);
  },

  // callcenter only, doesn't save favorites online
  replaceFavorites: function(favorites) {
    this.clearFavorites();
    const list = favorites.map((favorite) => favorite.attributes);
    this.reset(list);
    this.save();
  },

  addFavorite: function(favorite) {
    log("add favorite", favorite.id)
    const localItem = this.add(favorite.attributes);

    // save item online if we are logged in
    if (guestStore.has("token")) {
      guestStore.favorites.add(favorite.attributes);
      guestStore.favorites.save();

    // otherwise just store it in localstorage
    } else {
      localItem.save();
    }
  },

  removeFavorite: function(favorite) {
    log("remove favorite", favorite.id)
    let localItem = this.get(favorite.id);

    // delete item online if we are logged in
    if (guestStore.has("token")) {
      const guestItem = guestStore.favorites.get(favorite.id);
      guestStore.favorites.remove(guestItem);
      guestStore.favorites.save();
      this.remove(localItem);

    // otherwise just delete it locally
    } else {
      localItem.destroy();
    }
  },

  toggleFavorite: function(favorite) {
    if (this.get(favorite.id)) {
      this.removeFavorite(favorite);
    } else {
      this.addFavorite(favorite);
    }
  },

  /* save all favorites to persistent storage */
  save: function() {
    log("favorites saving", this.models);
    if (guestStore.has("token")) {
      guestStore.favorites.save();
    } else {
      this.each(function(model) {
        Backbone.sync("update", model)
      })
    }
  },

  clearFavorites: function() {
    if (guestStore.has("token")) {
      guestStore.favorites.reset();
      guestStore.favorites.save();
    } else {
      let clonedList = this.models.slice();
      for (let i = 0; i < clonedList.length; i++){
        clonedList[i].destroy();
      }
    }

    this.reset();
  },

  /* clear local favorites after guest logout */
  handleLogout: function() {
    this.reset();
  }
});

let favoriteStore = new FavoriteCollection();
export default favoriteStore;
