import React from "react";
import Search from "js/views/frontPage/Search";
import Header from "js/views/widgets/Header";

const FrontPageHeader = function () {
  return (
    <Header color="white" className="frontpageHeader">
      <Search/>
    </Header>
  );
};

export default FrontPageHeader;
