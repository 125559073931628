import PropTypes from 'prop-types';
import React, { Component } from "react";
import ReactDOM from "react-dom";
import isString from "lodash/isString";
import {filterProps} from "js/libs/util";
import log from "js/main/log";
import Dropdown from "js/components/Dropdown";

// Get elements parent name for useful errors
const getParentType = (element) => {
  let parentType = "";
  try {
    let type = element._reactInternalInstance._currentElement._owner._currentElement.type;
    parentType = type.displayName || type.name;
  } catch(err) {
    log.error("react internal api changed:", err)
  }
  return parentType;
}

export default class DropdownView extends Component {
  componentDidMount() {
    const { init, onTrigger, title, fixed } = this.props;
    
    // check that our user passes the right props...
    if (!isString(title.props.className) ||
        title.props.className.indexOf("title") === -1)
    {
      const parentType = getParentType(this);
      throw new Error(`Dropdown title element in ${parentType} does not have 'title' class`);
    }

    let container = ReactDOM.findDOMNode(this.refs.container);
    this.dropdown = new Dropdown(container, init, {onTrigger, fixed});
  }

  render() {
    let {title, content, className, id, onClick} = this.props;
    const filteredProps = filterProps(this.props, ["id", "onClick"])
    return <div {...filteredProps} ref="container"
        className={"dropdown " + (className ? className : "")}>
      {title}
      <div className="wrapper">
        <div className="content">{content}</div>
      </div>
    </div>
  }
}

DropdownView.propTypes = {
  // custom props
  init: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  onTrigger: PropTypes.func,
  fixed: PropTypes.bool,
}