/* Entry Point for static pages and frontpage */
import "js/compat/variousPolyfills";
import "js/compat/scopeSelectorShim";
import "js/libs/common";
import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import callcenterMode from "js/libs/callcenterMode";
import tracking from "js/libs/tracking";
import SearchElement from "js/views/frontPage/Search";
import actions from "js/main/actions";
import log from "js/main/log";
import localeStore from "js/stores/localeStore";
import locale from "js/main/locale";
import Header from "js/views/widgets/Header";
import FrontPageHeader from "js/views/frontPage/FrontPageHeader";
import Splide from "@splidejs/splide";
import "@splidejs/splide/dist/css/splide.min.css";
import BlogPosts from "js/libs/BlogPosts";

if (localeStore.initialLanguageChanged) {
  log("reloading for language change")
  location.reload();
}

let initialized = false;
window.startApp = function() {
    if (initialized) return;

    // patchup cities when we know our language
    if (init.cities[0].id != -1)
        init.cities.unshift({
            id: -1,
            name: locale.chooseCity,
            noLat: 48.875554,
            noLong: 9.403503,
            swLat: 48.657861,
            swLong: 8.929031,
        });

    initialized = true;
    log("app startup - init:", window.init);

    // check for callcenter mode
    callcenterMode.init();
    tracking.init();

    // Mount frontPage search form
    const searchContainer = document.querySelector("#frontPage .searchContainer");
    if (searchContainer) {
        Modal.setAppElement(searchContainer);
        ReactDOM.render(React.createElement(SearchElement), searchContainer);
    }

    // Mount header
    const frontpageHeaderContainer = document.querySelector("#frontPage .headerContainer")
    const headerContainer = document.querySelector(".headerContainer");
    if (frontpageHeaderContainer) {
        // specialized frontpage header
        ReactDOM.render(React.createElement(FrontPageHeader), frontpageHeaderContainer);
    } else if (headerContainer){
        ReactDOM.render(React.createElement(Header), headerContainer);
    }

    // Use footer language changer
    let langSelect = document.querySelector(".langSelect select");
    if (langSelect) {
        langSelect.value = localeStore.lang;
        langSelect.addEventListener("change", function (event) {
            actions.changeLanguage(event.target.value);
        });
    }

    tracking.pageView(location.href.split("#")[0]);

    // Accordion
    let accordionList = document.querySelectorAll(".accordion");
    if (accordionList.length) {
        accordionList.forEach((accordion) => {
            const items = accordion.querySelectorAll(".accordion-item");
            items.forEach((item) => {
                item.querySelector(".accordion-item-title").addEventListener("click", (e) => {
                    if (!item.classList.contains("open")) {
                        items.forEach((item) => {
                            item.classList.remove("open");
                        });
                    }
                    item.classList.toggle("open");
                });
            });
        });
    }

    // splidejs - Slider

    if (document.querySelector('#splide-cities')) {
        new Splide("#splide-cities", {
            drag: true,
            pagination: false,
            gap: "0em",
            type: "loop",
            perPage: 5,
            breakpoints: {
                2435: {
                    perPage: 4,
                },
                1850: {
                    perPage: 3,
                },
                992: {
                    perPage: 2,
                },
                480: {
                    perPage: 1,
                },
            },
        }).mount();
    }

    if (document.querySelector('#splide-offer')) {
        new Splide("#splide-offer", {
            drag: true,
            pagination: false,
            gap: "1em",
            type: "loop",
            perPage: 3,
            breakpoints: {
                5000: {
                    perPage: 3,
                },
                1700: {
                    perPage: 3,
                },
                1200: {
                    perPage: 3,
                },
                992: {
                    perPage: 2,
                },
                480: {
                    perPage: 1,
                },
            },
        }).mount();
    }

    // Posts from UrbanBnB-Blog
    const blog = new BlogPosts("https://www.urbanbnb.de/v1/blog/", "#blog-post-template", "#blog-posts");
    blog.setMoreLinkElement(".blog-posts-more-link");

    // Host Overlay
    window.setTimeout(() => {
        const hostOverlayWrapper = document.querySelector("#hostOverlay");
        if (hostOverlayWrapper) {
            const closeHandler = (e) => {
                e.preventDefault();
                e.stopPropagation();
                hostOverlayWrapper.classList.add("show", true);
                hostOverlayWrapper.classList.remove("show");
                localStorage.setItem('hostOverlayAcknowledged', true);

                return false;
            }

            hostOverlayWrapper.addEventListener("click", closeHandler);
            hostOverlayWrapper.querySelector(".hostOverlay").addEventListener("click", (e) => {
                e.stopPropagation();
            });
            hostOverlayWrapper.querySelector(".close-image").addEventListener("click", closeHandler);

            if (!localStorage.getItem('hostOverlayAcknowledged')) {
                hostOverlayWrapper.classList.add("show");
            }
        }
    }, 1000);

}

