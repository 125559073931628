import assign from "lodash/assign";
import Backbone from "js/libs/Backbone";
import config from "js/main/config";
import log from "js/main/log";

const KeyValBase = function(store) {
  return store.extend({
    url: function() {
      return config.url(`${this.userStore.type}/${this.userStore.id}/storage/${this.key}`);
    },
    save: function(options) {
      this.sync("create", this, assign({
        headers: {
          "X-Nd-Token": this.userStore.attributes.token,
        },
        contentType: "application/json"
      }, options));
    },
    update: function(options) {
      this.fetch(assign({
        headers: {
          "X-Nd-Token": this.userStore.attributes.token
        }
      }, options));
    },
  });
}

export const KeyValItemStore = KeyValBase(Backbone.Model.extend({
  constructor: function(attributes, options) {
    Backbone.Model.apply(this, arguments);

    if (options && options.userStore && options.key) {
      this.userStore = options.userStore;
      this.key = options.key;

      this.listenTo(this.userStore, "login", this.update);
      this.userStore.loggedIn((loggedIn) => {
        if (loggedIn)
          this.update();
      });
    }
  },
  sync: function(method, model, options) {
    switch(method) {
    case "create":
      return Backbone.sync.call(this, "create", model, assign({
        data: JSON.stringify(this.attributes)
      }, options));
    default:
      return Backbone.sync.apply(this, arguments);
    }
  }
}));

export const KeyValListStore = KeyValBase(Backbone.Collection.extend({
  constructor: function(attributes, options) {
    Backbone.Collection.apply(this, arguments);

    if (options && options.userStore && options.key) {
      this.userStore = options.userStore;
      this.key = options.key;

      this.listenTo(this.userStore, "login", this.update);
      this.userStore.loggedIn((loggedIn) => {
        if (loggedIn)
          this.update();
      });
    }
  },
  sync: function(method, collection, options) {
    switch(method) {
    case "create":
      return Backbone.sync.call(this, "create", collection, assign({
        data: JSON.stringify(collection.models)
      }, options));
    default:
      return Backbone.sync.apply(this, arguments);
    }
  },
}));
