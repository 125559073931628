import "less/views/widgets/dateInput";
import React from "react";
import ReactDOM from "react-dom";
import Dropdown from "./Dropdown";

class DateInputBase extends React.Component{
  constructor(props){
    super();
    let dateProp;
    if(props.type && props[props.type]){
      dateProp= props[props.type]
    }
    let d= dateProp;
    if(!dateProp){
      d= props.initDate ? new Date(props.initDate) : new Date(init.serverTime)

      /* Advance the day after 18:00 (last possible time for lastMinute booking) */
      if(d.getHours() >= 18)
        d.setDate(d.getDate() + 1);
    }
    d.setHours(0, 0, 0, 0);
    this.state= {
      date: d,
      text: dateProp ? d.toDateString() : "",
      selected: false
    };
  }

  dropdownInit(that){
    const input = ReactDOM.findDOMNode(this.refs.input);
    input.addEventListener("focus", () => that.open());
    this.closeDropdown= () => that.close();
  }

  // update procedures
  shouldComponentUpdate(newProps, newState){
    let dateProp;
    if(newProps.type && newProps[newProps.type] !== undefined){
      dateProp= newProps[newProps.type];
    }
    const d= this.state.date.toDateString(),
    propd= dateProp ? dateProp.toDateString() : d,
    stated= newState.date.toDateString();

    return (!dateProp || newState.text !== this.state.text || d !== propd || d !== stated);
  }

  componentWillReceiveProps(newProps){
    if(newProps.type && newProps[newProps.type] !== undefined){
      let dateProp= newProps[newProps.type];
      if(!dateProp)
        this.setState({
          date: newProps.initDate ? new Date(newProps.initDate) : new Date(init.serverTime),
          text: ""
        });
      else
        this.setState({date: dateProp, text: dateProp.toDateString()});
    }
  }

  setDate(date){
    this.setState({date, text: date.toDateString(), selected: true});
    if(this.props.onSelect){
      this.props.onSelect(date);
    }
  }

  focus(){
    ReactDOM.findDOMNode(this.refs.input).focus();
  }

  handleChange(event){
    let text= event.target.value;
    let lastLetter= text[text.length - 1];
    if(text.length < 11 && (text.length === 0 || lastLetter.match(/\d|\./))){
      this.setState({text: text});
      let match= text.match(/(\d\d)\.(\d\d)\.(\d\d\d\d)/);
      if(match){
        let date= new Date();
        date.setYear(match[3]);
        date.setMonth(match[2] - 1, match[1]);
        date.setHours(0, 0, 0, 0);
        this.setDate(date);
      }
    }
  }

  handleBlur(event){
    let text= event.target.value;
    let match= text.match(/(\d\d|\d)\.(\d\d|\d)(\.(\d\d\d\d|\d\d)|)/);
    if(match){
      let date= new Date();
      if(match[4]){
        date.setYear(match[4].length === 2 ? "20" + match[4] : match[4]);
      }
      date.setMonth(match[2] - 1, match[1]);
      date.setHours(0, 0, 0, 0);
      if(date.getTime() !== this.state.date.getTime()){
        this.setDate(date);
      }
    }
  }

  handleCalendarSelect(date){
    const today = new Date(init.serverTime),
      /* Default to true for normal date inputs without roomtype integration */
      isLastMinute= this.props.roomtype ? this.props.roomtype.address.lastMinute : true;
    if((isLastMinute || today.beforeTime(date, init.config.bookingHourOffset)) &&
      (!isLastMinute || today.beforeTime(date, init.config.lastMinuteHourOffset))){
      let selectAfter= this.props.type === "from" && (!this.props.to || !date.isBefore(this.props.to));
      let selectBefore= this.props.type === "to" && (!this.props.from || !this.props.from.isBefore(date));
      this.setDate(date);

      this.closeDropdown();
      if(selectAfter){
        this.waitForClick(() => this.props.focusAfter());
      }else if(selectBefore){
        this.waitForClick(() => this.props.focusBefore());
      }
    }
  }

  waitForClick(callback){
    this.waiting= callback;
  }

  handleClick(){
    if(this.waiting){
      this.waiting();
      this.waiting= null;
    }
  }

  //@overwrite
  getDropdownContent(){
    return "";
  }

  //@overwrite
  handleTrigger(){
    //noop
  }

  render(){
    const title = <div className="title">
      <input ref="input" type="text" readOnly title={this.state.text}
        value={this.state.text} onChange={this.handleChange.bind(this)}
        onBlur={this.handleBlur.bind(this)} disabled={this.props.disabled}
        placeholder={this.props.placeholder} />
    </div>;
    return <Dropdown
      onClick={this.handleClick.bind(this)}
      onTrigger={this.handleTrigger.bind(this)}
      className={`dateInput ${this.props.className ? this.props.className : ""}`}
      title={title}
      content={this.getDropdownContent()}
      init={this.dropdownInit.bind(this)}>
    </Dropdown>
  }
}

export default DateInputBase;
