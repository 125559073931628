/**
 *  Load google analytics and setup tracking code
 */
import noop from "lodash/noop";
import log from "js/main/log";
import config from "js/main/config";
import wrapScript from "js/libs/wrapScript";

window.ga = noop;
export const initAnalytics = () => {
  // Google - Conversion Tracking Tag

  setTimeout(
    () =>
      wrapScript("https://www.googletagmanager.com/gtag/js?id=G-74TJ7T6WY6"),
    500
  );

  window.dataLayer = window.dataLayer || [];

  gtag("js", new Date());
  gtag("config", "G-74TJ7T6WY6");

  // Google - Analytics

  if (!config.analyticsAPIKey) {
    log.warn("No analytics API key configured");
    return;
  }

  setTimeout(
    () => wrapScript("https://www.google-analytics.com/analytics.js"),
    500
  );
  window.ga = function () {
    (window.ga.q = window.ga.q || []).push(arguments);
  };
  window.ga.l = Date.now();

  reportAnalytics("create", config.analyticsAPIKey, "auto");
  reportAnalytics("set", "anonymizeIp", true);
};

export const reportAnalytics = function (action, ...args) {
  window.ga.apply(window, arguments);
};

export const gtag = function () {
  window.dataLayer.push(arguments);
};
