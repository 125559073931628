import Backbone from "js/libs/Backbone";
import UserStore from "js/stores/UserStore";
import {KeyValItemStore} from "js/libs/keyval";

const HostStore = function(){
  const Store = UserStore.extend({
    type: "host",
    localStorage: new Backbone.LocalStorage("hostStore", true),
  });

  const instance = new Store();
  instance.vars = new KeyValItemStore(null, {userStore: instance, key: "vars"});
  return instance;
}

export default new HostStore();
