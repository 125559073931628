import partial from "lodash/partial";
// import Raven from "raven-js";
import config from "js/main/config";
import * as Sentry from "@sentry/react";
import { SeverityLevels } from "@sentry/utils";
import { Severity } from "@sentry/react";

enum level {
  PLAIN = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
}

/* eslint-disable no-console */
const dynamicLogLevel = localStorage.getItem("logLevel");
const log = function (
  logFunc: (...args: any[]) => void,
  logLevel: level,
  ...messages: any[]
) {
  if (
    logLevel >= config.logLevel ||
    (dynamicLogLevel && logLevel >= parseInt(dynamicLogLevel))
  )
    logFunc.apply(console, messages);
};

type Logger = {
  (...messages: any[]): void;
  info?: (...messages: any[]) => void;
  warn?: (...messages: any[]) => void;
  error?: (...messages: any[]) => void;
  trace?: (msg: any) => void;
  exception?: (err: Error) => void;
};

type CompleteLogger = {
  (...messages: any[]): void;
  info: (...messages: any[]) => void;
  warn: (...messages: any[]) => void;
  error: (...messages: any[]) => void;
  trace: (msg: any) => void;
  exception: (err: Error) => void;
};

let Log: Logger = partial(log, console.log, level.PLAIN);
Log.info = partial(log, console.info, level.INFO);
Log.warn = partial(log, console.warn, level.WARN);
Log.error = partial(log, console.error, level.ERROR);
Log.trace = (msg) => {
  /*if (Raven.isSetup())
    Raven.captureMessage(msg, {
      level: "info"
    });*/
  Sentry.captureMessage(msg, Severity.Info);

  if (window.console && console.info) console.info(msg);
};

Log.exception = (err) => {
  /*if (Raven.isSetup())
    Raven.captureException(err);*/
  Sentry.captureException(err);

  if (window.console && console.error) console.error(err);
};

export default Log as CompleteLogger;
