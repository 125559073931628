import config from "js/main/config";
// import { init } from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

var sentryLoaded = false;

export const initSentry = () => {
  if (config.sentryKey) {
    // init({
    //   dsn: config.sentryKey,
    //   integrations: [new Integrations.BrowserTracing()],
    //   tracesSampleRate: 1.0,
    //   allowUrls: ["www.urbanbnb.de", "dev.urbanbnb.de"],
    //   environment: config.environment,
    // });

    try {

      window.sentryOnLoad = () => {
        if (sentryLoaded) return;
        sentryLoaded = true;

        console.log("Sentry script loaded");
        window.Sentry.init({
          dsn: config.sentryKey,
          // Alternatively, use `process.env.npm_package_version` for a dynamic release version
          // if your build tool supports it.
          // release: "my-project-name@2.3.12",
          integrations: [
            window.Sentry.browserTracingIntegration(),
            window.Sentry.replayIntegration({
                    maskAllText: false,
                    maskAllInputs: false,
                    blockAllMedia: false,
                    networkDetailAllowUrls: [/\/v1\//],
                    mutationLimit: 99999,
                    mutationBreadcrumbLimit: 2000,
            }),
          ],
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for tracing.
          // We recommend adjusting this value in production
          // Learn more at
          // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
          tracesSampleRate: 1.0,
          // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
          tracePropagationTargets: [
            "localhost",
            "urbanbnb.de",
            "dev.urbanbnb.de",
            "www.urbanbnb.de",
            "https://urbanbnb.de",
            "https://dev.urbanbnb.de",
            "https://www.urbanbnb.de",
          ],
          // Capture Replay for 10% of all sessions,
          // plus for 100% of sessions with an error
          // Learn more at
          // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
          replaysSessionSampleRate: 1.0,
          replaysOnErrorSampleRate: 1.0,
        });

        console.log("Sentry init done");

      };

      const script = document.createElement("script");
      script.src = "/assets/bundle.tracing.replay.min.js";
      document.body.appendChild(script);
      script.onload = window.sentryOnLoad;
    } catch (e) {
      console.error("Failed to init sentry", e);
    }
  }
};
