import Backbone from "js/libs/Backbone";
import UserStore from "js/stores/UserStore";
import {KeyValListStore} from "js/libs/keyval";

const GuestStore = function(){
  const Store = UserStore.extend({
    type: "guest",
    localStorage: new Backbone.LocalStorage("guestStore", true),
  });

  const instance = new Store();
  instance.favorites = new KeyValListStore(null, {userStore: instance, key: "favorites"});
  return instance;
}

export default new GuestStore();
