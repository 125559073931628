import throttle from "lodash/throttle";
import React from "react";
import createReactClass from "create-react-class";
import ReactDOM from "react-dom";
import { getLoginUrl } from "js/libs/url";
import callcenterMode from "js/libs/callcenterMode";
import guestStore from "js/stores/guestStore";
import hostStore from "js/stores/hostStore";
import actions from "js/main/actions";
import locale from "js/main/locale";
import log from "js/main/log";
import FavoriteDropdown from "js/views/widgets/FavoriteDropdown";
import BackboneMixin from "js/views/BackboneMixin";
import StaticMenuDropdown from "./StaticMenuDropdown";
import localeStore from "js/stores/localeStore";

const otherLang = locale.lang === "de" ? "en" : "de";
const handleLangChange = () => actions.changeLanguage(otherLang);

class HeaderButtons extends React.Component {
  constructor(props) {
    super(props);
  }

  handleLogout() {
    actions.logout();
    actions.navigate("/ergebnisse", { trigger: true });
  }

  render() {
    let offerUrl = null;
    if (callcenterMode.isActive) {
      offerUrl = callcenterMode.offerUrl();
    }

    return (
      <div className="headerButtons">
        {/* Don't show favorites for hosts */}
        {!hostStore.has("token") && <FavoriteDropdown />}

        {/* Show host/guest logout */}
        {(guestStore.has("token") || hostStore.has("token")) && (
          <button className="ubtn" onClick={this.handleLogout}>
            <span>Logout</span>
            <span className="icon icon-off" />
            <br />
          </button>
        )}

        {/* Show host/guest specific area */}
        {guestStore.has("token") && (
          <a className="ubtn ubtn-light" href="gast/buchung">
            <span className="highlight">{locale.guestArea}</span>
            <span className="icon icon-user" />
          </a>
        )}
        {hostStore.has("token") && (
          <a className="ubtn" href="gastgeber/objekte">
            <span className="highlight">{locale.hostArea}</span>
            <span className="icon icon-user" />
          </a>
        )}

        {/* Show login buttons */}
        {!guestStore.has("token") &&
          !hostStore.has("token") && [
            <a className="ubtn ubtn-light" href={getLoginUrl()} key="2">
              <span>{locale.guestLogin}</span>
              <img
                src="/assets/images/briefcase-account.svg"
                alt="{locale.guestLogin}"
              />
            </a>,

            <a
              className={`ubtn ${this.props.host ? "ubtn-secondary" : ""}`}
              href="/gastgeberlogin"
              key="1"
            >
              <span>{locale.becomeHost}</span>
              <img
                src="/assets/images/key-chain.svg"
                alt="{locale.hostLogin}"
              />
            </a>,
          ]}

        {/* Show callcenter buttons */}
        {offerUrl && [
          <a className="ubtn ubtn-light" href={offerUrl} key="1">
            <span className="icon icon-doc-inv" />
            <br />
            <span>{locale.sendOffer}</span>
          </a>,

          location.pathname === "/ergebnisse" && (
            <button
              key="2"
              className="ubtn ubtn-light"
              onClick={actions.favoriteAllResults}
              title="Alle Ergebnisse in Merkliste übernehmen"
            >
              <span className="icon icon-plus-circled" />
              <span className="resultCount">{this.props.results}</span>
              <br />
              <span>{locale.confirmAll}</span>
            </button>
          ),
        ]}

        {/* Static menu */}
        <StaticMenuDropdown />
      </div>
    );
  }
}

/* Header */
export default createReactClass({
  displayName: "Header",
  mixins: [BackboneMixin],

  getBackboneState: function () {
    return {
      open: false,
      results: null,
    };
  },

  watchBackboneProps: function (props, listenTo) {
    listenTo(hostStore, "change:token");
    listenTo(guestStore, "change:token");
    listenTo(actions, "setResultCount", (results) =>
      this.setState({ results })
    );
  },

  handleResize: throttle(function () {
    const logo = ReactDOM.findDOMNode(this.refs.logo),
      content = ReactDOM.findDOMNode(this.refs.content),
      buttons = ReactDOM.findDOMNode(this.refs.buttons);

    if (!logo || !content || !buttons) return;

    content.style.display = "inline-block";
    content.style.maxWidth = "";
    const maxWidth =
      window.innerWidth - logo.scrollWidth - buttons.scrollWidth - 30;

    if (content.scrollWidth > maxWidth || window.innerWidth < 768) {
      content.style.display = "block";
      content.style.maxWidth = "";
    } else {
      content.style.display = "inline-block";
    }
  }, 210),

  handleOpen: function () {
    this.setState({ open: !this.state.open });
  },

  handleCookie: function (e) {
    e.preventDefault();
    window.ccShowBar();
    return false;
  },

  handleScroll: throttle(function (e) {
    if (window.scrollY > 200) {
      this.setState({ sticky: true });
      //  document.body.classList.add("sticky-header");
    } else {
      this.setState({ sticky: false });
      // document.body.classList.remove("sticky-header");
    }

    if (window.scrollY > 200) {
      this.setState({ stickyShow: true });
      this.setState({ color: "blue" });
    } else {
      this.setState({ stickyShow: false });
      this.setState({ color: null });
    }
  }, 500),

  componentDidMount: function () {
    if (!this.props.login) {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
    }
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },

  componentWillUnmount: function () {
    if (!this.props.login) {
      window.removeEventListener("resize", this.handleResize);
    }
    window.removeEventListener("scroll", this.handleScroll);
  },

  render: function () {
    const color = this.state.color || this.props.color || "blue";
    let className = `${this.props.className || ""} ${color} toolbar`;
    if (this.state.sticky) {
      className += " sticky";
    }
    if (this.state.stickyShow) {
      className += " sticky-show";
    }
    const styles = { position: this.state.stickyShow ? "fixed" : "absolute" };
    return (
      <div class="header-container">
        <div style={{ height: "136px" }} className="header-container-spacer"></div>
        <header className={className} style={styles}>
          <div className="layout justify-content-space-between align-items-center layout-gap-md pt-md pb-md">
            <a
              className="logo"
              data-bypass
              href={`/?lang=${localeStore.lang}`}
              ref="logo"
            >
              <img
                src={`/assets/images/logo_${color}.svg`}
                alt="Urban Bed Breakfast"
              />
            </a>

            <div className="headerIconBar">
              <a href="https://www.instagram.com/urbanbnb/" target="_blank">
                <img src="/assets/images/instagram.svg" alt="Instagram" />
              </a>

              <a href="https://www.facebook.com/urbanbnb/" target="_blank">
                <img src="/assets/images/facebook.svg" alt="Facebook" />
              </a>

              <a href="https://www.youtube.com/user/NDurban18" target="_blank">
                <img src="/assets/images/youtube.svg" alt="YouTube" />
              </a>

              <a href="/blog">
                <img src="/assets/images/bnb.blog.svg" alt="BnB Blog" />
              </a>

              <button
                className="langLink"
                data-lang={otherLang}
                onClick={handleLangChange}
              >
                <img
                  src={`/assets/images/flag_${
                    otherLang == "de" ? "german" : "english"
                  }.svg`}
                  alt={otherLang.toUpperCase()}
                />
              </button>

              <a href="#" data-bypass onClick={this.handleCookie}>
                <img src="/assets/images/bxs_cookie.svg" alt="Cookie Bar" />
              </a>
            </div>

            <div className={"headerMenu" + (this.state.open ? " open" : "")}>
              <HeaderButtons
                ref="buttons"
                results={this.state.results}
                host={this.props.host}
              />
            </div>
          </div>

          {this.props.children ? (
            <div className="headerContent">
              <div className="container">{this.props.children}</div>
            </div>
          ) : null}
        </header>
      </div>
    );
  },
});
