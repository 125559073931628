import React from "react";
import find from "lodash/find";
import clone from "lodash/clone";
import locale from "js/main/locale";
import log from "js/main/log";
import { serialize } from "js/libs/url";
import { getPersonOptions } from "js/libs/util";
import DateInput from "js/views/widgets/DateInput";
import Tooltip from "js/views/widgets/Tooltip";
import ConfirmModal from "js/views/widgets/ConfirmModal";
import RoomtypeLookupModal from "js/views/widgets/RoomtypeLookupModal";

/* Search */
export default class extends React.Component {
  state = {
    city: init.cities[0],
    from: null,
    to: null,
    viewport: null,
    location: null,
    children: 0,
    adults: 1,
    modal: null,
  };
  setCity = (city, callback = () => log("default callback")) => {
    log("set city", city);
    this.setState(
      {
        viewport: {
          nolat: city.noLat,
          nolong: city.noLong,
          swlat: city.swLat,
          swlong: city.swLong,
        },
        city,
      },
      callback
    ); // execute callback after state update
  };
  handleCitySelect = (event) => {
    const city = find(init.cities, { id: Number(event.target.value) });
    this.setCity(city);
  };

  handleAddressSelect = (geometry, location, viewport = null) => {
    const newState = { location };
    log("selected address - location:", location, "viewport:", viewport);
    this.setState(newState);
  };

  handleFromSelect = (date) => {
    if (!this.state.city || this.state.city.id == -1) {
      this.setCity(init.cities[0], () => {
        log("no city set, using default city", init.cities[0], "cities");
        this.updateDateState(date);
      });
    } else {
      this.updateDateState(date);
    }
  };

  updateDateState = (date) => {
    const state = clone(this.state);
    state.from = new Date(date);
    if (state.to === null || !state.from.isBefore(state.to)) {
      state.to = new Date(state.from);
      state.to.setDate(state.from.getDate() + 1);
    }
    this.setState(state);
  };
  handleToSelect = (date) => {
    const state = clone(this.state);
    state.to = new Date(date);
    if (state.from === null || !state.from.isBefore(state.to)) {
      state.from = new Date(state.to);
      state.from.setDate(state.to.getDate() - 1);
    }
    this.setState(state);
  };

  handlePersonSelect = (key, event) => {
    const state = clone(this.state);
    state[key] = Number(event.target.value);
    this.setState(state);
  };

  handleFocus = (ref) => {
    this.refs[ref].focus();
  };

  showCityModal = () => {
    const handleModal = (confirm) => {
      this.setState({ modal: null });
    };
    const modal = (
      <ConfirmModal
        callback={handleModal}
        msg={locale.chooseCityMsg}
        noCancel={true}
      />
    );
    this.setState({ modal });
  };

  getParams = () => {
    if (!this.state.city) {
      this.setCity(init.cities[0]);
    }

    const { viewport, location } = this.state,
      text = this.refs.addressSearch
        ? this.refs.addressSearch.state.text
        : null;

    const params = {
      from: this.state.from ? this.state.from.toISODate() : null,
      to: this.state.to ? this.state.to.toISODate() : null,
      query: text ? text : null,
      adults: this.state.adults,
      children: this.state.children,

      // prefer location over viewport
      swlat: !location && viewport ? viewport.swlat : null,
      swlong: !location && viewport ? viewport.swlong : null,
      nolat: !location && viewport ? viewport.nolat : null,
      nolong: !location && viewport ? viewport.nolong : null,
      locationLat: location ? location.lat : null,
      locationLong: location ? location.long : null,
      cityset: this.state.city.id == -1 ? 0 : 1,
    };
    log("get params", params);
    return params;
  };

  handleFind = (event) => {
    event.preventDefault();

    if (!this.state.viewport && !this.state.location) {
      this.showCityModal();
      return;
    }

    // build link
    let params = this.getParams();
    for (let prop in params) {
      if (params[prop] === undefined || params[prop] === null) {
        delete params[prop];
      }
    }
    window.location = `/ergebnisse?${serialize(params)}`;
  };

  handleRoomtypeNumber = (event) => {
    event.preventDefault();

    const handleModal = function (confirm, options) {
      if (confirm) {
        window.location = `/details/${options.id}`;
      }
      this.setState({ modal: null });
    }.bind(this);
    this.setState({ modal: <RoomtypeLookupModal callback={handleModal} /> });
  };

  render() {
    // FIXME: add to init config
    const MAX_PERSONS = 15;

    let remaining = MAX_PERSONS - this.state.adults - this.state.children;
    return (
      <form>
        <div className="search-field-group">
          <div className="citySelect">
            <select value={this.state.city.id} onChange={this.handleCitySelect}>
              {init.cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="search-field-group">
          <DateInput
            type="from"
            ref="checkin"
            focusAfter={this.handleFocus.bind(this, "checkout")}
            onSelect={this.handleFromSelect}
            placeholder="Check-in"
            from={this.state.from}
            to={this.state.to}
            className="checkin"
          />

          <DateInput
            type="to"
            ref="checkout"
            focusBefore={this.handleFocus.bind(this, "checkin")}
            onSelect={this.handleToSelect}
            placeholder="Check-out"
            from={this.state.from}
            to={this.state.to}
            className="checkout"
          />
        </div>

        <div className="search-field-group last">
          <div className="adultSelect">
            <select
              onChange={this.handlePersonSelect.bind(this, "adults")}
              value={this.state.adults}
            >
              {getPersonOptions(1, this.state.adults, remaining, locale.adults)}
            </select>
          </div>

          <div className="childSelect">
            <select
              onChange={this.handlePersonSelect.bind(this, "children")}
              value={this.state.children}
            >
              {getPersonOptions(
                0,
                this.state.children,
                remaining,
                locale.children
              )}
            </select>
          </div>
        </div>

        <button onClick={this.handleFind} className="ubtn ubtn-secondary">
          <span>{locale.findRoomtype}</span>
          <img src="/assets/images/magnify.svg" alt="{locale.findRoomtype}" />
        </button>

        <div className="discountBubble">
          <div className="large">5%</div>
          {locale.onlineDiscountMsg}
          <Tooltip content={locale.onlineDiscountTooltip} style="modal" />
        </div>

        <div className="searchByHostNumber">
          <button className="link" onClick={this.handleRoomtypeNumber}>
            {locale.enterRoomtypeNumber}
          </button>
        </div>

        {this.state.modal}
      </form>
    );
  }
}
