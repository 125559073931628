import noop from "lodash/noop";
import assign from "lodash/assign";
import bindAll from "lodash/bindAll";
import ajax from "js/libs/NativeAjax";
import Backbone from "js/libs/Backbone";
import tracking from "js/libs/tracking";
import {serialize} from "js/libs/url";
import {parseErrorResponse} from "js/libs/util";
import actions from "js/main/actions";
import config from "js/main/config";
import log from "js/main/log";

/* UserStore: Model with Session persistence */
export default Backbone.Model.extend({
  initialize: function() {
    bindAll(this);
    this.bindEventListeners();
    this.fetch();

    this.loginListeners = [];
    this.verified = false;
    if (this.has("token")) {
      log(`store:${this.type} has token: probably logged in`, this.attributes);
      this.ajaxFetch();
    }
  },
  bindEventListeners: function() {
    this.listenTo(actions, "login", this.handleLogin);
    this.listenTo(actions, "logout", this.logout);
  },
  notifyLoginListeners: function(result) {
    for (let i = 0; i < this.loginListeners.length; i++) {
      const listener = this.loginListeners[i];
      listener(result);
    }
    this.loginListeners = [];
  },
  ajaxFetch: function() {
    this.fetch({
      ajaxSync: true,
      unset: false,
      url: config.url(`${this.type}/current`),
      headers: {
        "X-Nd-Token": this.get("token")
      },
      success: (model, data) => {
        this.save(data);
        this.verified = true;
        this.notifyLoginListeners(true);
        log("login verified")
      },
      error: (model, xhr) => {
        // logout in error case
        this.logout();
        this.notifyLoginListeners(false);
        if (xhr.status === 401) {
          log.info("login session expired; logging out");
        } else {
          log.error(`fetch ${xhr.responseURL} responded ${xhr.status} ${xhr.statusText}`);
        }
      }
    });
  },
  ajaxSave: function(attributes, options) {
    ajax(assign({
      ajaxSync: true,
      url: config.url(`${this.type}/${this.id}`),
      type: "PUT",
      data: JSON.stringify(attributes),
      dataType: "json",
      headers: {
        "X-Nd-Token": this.get("token")
      },
      error: (xhr, code, error) => {
        log.error(`fetch ${xhr.responseURL} responded ${xhr.status} ${xhr.statusText}`);
      },
    }, options));
  },
  ajaxValidate: function(attributes, options) {
    let params = options.params || {};
    ajax(assign({
      ajaxSync: true,
      url: config.url(`${this.type}/validate?` + serialize(params)),
      type: "POST",
      data: JSON.stringify(attributes),
      dataType: "json",
      headers: {
        "X-Nd-Token": this.get("token")
      },
      error: (xhr, code, error) => {
        log.error(`fetch ${xhr.responseURL} responded ${xhr.status} ${xhr.statusText}`);
      },
    }, options));
  },
  registerUser: function(attributes, options) {
    ajax(assign({
      ajaxSync: true,
      url: config.url(`${this.type}`),
      type: "POST",
      data: JSON.stringify(attributes),
      dataType: "json",
      headers: {
        "X-Nd-Token": this.get("token")
      },
      error: (xhr, code, error) => {
        log.error(`fetch ${xhr.responseURL} responded ${xhr.status} ${xhr.statusText}`);
      },
    }, options));
  },
  login: function(params, options) {
    const callback = options.callback || noop;

    ajax(assign({
      url: config.url(`${this.type}/login`),
      type: "POST",
      contentType: "application/x-www-form-urlencoded",
      data: serialize(params),
      success: (data) => {
        const attributes = data[this.type],
          token = data.token;

        this.handleLogin(this.type, attributes, token);
        callback(true, data);
      },
      error: (xhr, code, error) => {
        const parsedError = parseErrorResponse(xhr.response)
        // handle blacklisted users with modal
        if (code == 401 && parsedError && parsedError.key == "401102") {
          actions.showModal(parsedError.msg, {noCancel: true, markdown: true});
        } else {
          callback(false, xhr.response);
        }

        if (code !== 401)
          log.error(error);
      },
    }, options))
  },
  // handle login of userStores
  handleLogin: function(type, attributes, token) {
    // only allow one logged in userStore
    if (type === this.type && !this.has("token")) {
      this.set(attributes);
      this.set({token});
      this.save();
      log(`${this.type} logged in`, token);

      // let other userStores know that you logged in
      actions.login(this.type);
      this.trigger("login");
      tracking.login(this.type, this.id);
    } else if (type !== this.type && this.has("token")) {
      this.logout();
    }
  },
  loggedIn: function(callback) {
    if (!this) {
      callback(false);
    } else {
      if (this.verified) {
        callback(true);
      } else {
        this.loginListeners.push(callback);
      }
    }
  },
  logout: function() {
    this.destroy();
    this.clear();
    this.verified = false;
    this.trigger("logout");
    this.bindEventListeners();
    log(`${this.type} logged out`, this.attributes);
    tracking.logout(this.type);
  },
  getMail: function() {
    return `${this.get("firstname")} ${this.get("lastname")} <${this.get("email")}>`;
  }
});
