import noop from "lodash/noop";
import log from "js/main/log";

/* wrapScript */
export default function(src, callback) {
  log("Wrapping Script", src)
  let script = document.createElement("script");
  script.async = true;
  script.defer = true;
  script.crossorigin = "anonymous";
  script.src = src;
  script.onerror = function(event) {
    log.info("wrapped script didn't load");
    // don't pass errors from wrapped scripts
    // to global handling
    return true;
  }
  script.onload = callback || noop;
  document.body.appendChild(script);
}
