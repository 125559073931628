/**
 * Extensions of Builtins
 *
 * yes there are better ways ...
 */
import partial from "lodash/partial";
import isDate from "lodash/isDate";
import isUndefined from "lodash/isUndefined";


 /* eslint-disable no-extend-native */
String.prototype.padLeft = function(length, fill="0") {
  return new Array(length - this.length + 1).join(fill) + this;
}

String.prototype.padRight = function(length, fill="0") {
  return this + new Array(length - this.length + 1).join(fill);
}

String.prototype.format = function() {
  let args= arguments;
  return this.replace(/{(\d+)}/g, function(match, number) {
    return !isUndefined(args[number]) ?
    args[number] : match;
  });
};

Date.prototype.isBefore = function(other) {
  if(!isDate(other))
    throw new Error('isBefore expects date');

  return this.getFullYear() < other.getFullYear() ||
    (this.getFullYear() === other.getFullYear() && (this.getMonth() < other.getMonth() ||
    (this.getMonth() === other.getMonth() && this.getDate() < other.getDate())));
};

Date.prototype.beforeTime = function(other, offset) {
  let tmp = new Date(other);
  if(offset)
    tmp.setHours(-offset);
  return this < tmp;
}

// calculate date with UTC+02
let makeLocalDate = function(string) {
  var shifted = new Date(this);
  shifted.setUTCHours(shifted.getUTCHours() + 2);
  return string.format(
    shifted.getUTCFullYear(),
    String(shifted.getUTCMonth() + 1).padLeft(2),
    String(shifted.getUTCDate()).padLeft(2)
  );
}

Date.prototype.toISODate = partial(makeLocalDate, "{0}-{1}-{2}");
Date.prototype.toGermanDateString = partial(makeLocalDate, "{2}.{1}.{0}");
Date.prototype.toDateString = partial(makeLocalDate, "{2}.{1}.{0}"); // TODO: respect language
