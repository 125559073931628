import {serialize, deserialize} from "js/libs/url";
import uiStore from "js/stores/uiStore";
import log from "js/main/log";

export default {
  init: function() {
    const params = deserialize(location.search.slice(1));

    if (Modernizr.sessionstorage && params.callcenter &&
        (params.callcenter === "1" || params.callcenter === "0")) {
      sessionStorage.setItem("callcenterMode", params.callcenter);
      log("Setting callcenterMode:", params.callcenter)
    }

    if (this.isActive) {
      log.info("Callcenter mode is active");
    }
  },
  get isActive() {
    return sessionStorage.getItem("callcenterMode") === "1";
  },
  offerUrl: () => "/angebot?" + serialize({
    from: uiStore.from ? uiStore.from.toISODate() : null,
    to: uiStore.to ? uiStore.to.toISODate() : null,
    adults: uiStore.adults,
    children: uiStore.children
  })
}
