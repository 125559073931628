import React from "react";
import Calendar from "./Calendar";
import DateInputBase from "./DateInputBase";

export default class DateInput extends DateInputBase {
  getDropdownContent(){
    return <Calendar
        ref="calendar" date={this.state.date} selected={this.state.date}
        onSelect={this.handleCalendarSelect.bind(this)}/>;
  }
}
