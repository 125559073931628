import actions from "js/main/actions";
import log from "js/main/log";

// serialize flat url params
export function serialize(obj) {
  let str= [];
  for(var p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p) && obj[p] != undefined) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

// convert url params to object
export function deserialize(string) {
  let result = {};
  if (string) {
    const parts = string.split(/&|\?/);
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i].split("=");
      if (part.length === 2)
        result[decodeURIComponent(part[0])] = decodeURIComponent(part[1]);
    }
  }
  return result;
}

// decode array parameter
export function decodeList(string){
  if(string.indexOf(",") !== -1){
    let arr= [];
    let parts= string.split(",")

    for(let j=0; j<parts.length; j++){
      arr.push(parts[j])
    }
    return arr;
  }else{
    return [string];
  }
}

// escape url while preserving umlauts
export function escape(url) {
  return encodeURIComponent(url).replace("%C3%A4", "ä").replace("%C3%B6", "ö").replace("%C3%BC", "ü").replace("%C3%9F", "ß");
}

export function getLoginUrl(type) {
  let path = window.location.pathname + window.location.search;
  
  if(type === "host") {
    return `/gastgeberlogin?${serialize({p: path})}` 
  } else {
    if (window.location.pathname === "/") {
      path = "/gast/buchung" + window.location.search
    }
    return `/gastlogin?${serialize({p: path})}`
  }
}

// redirect to login page with current path as parameter
export function redirectUnauthorized(type){
  if (!type)
    throw new Error("redirectUnauthorized needs type");

  log("Redirect Unauthorized");

  actions.logout();
  location.href = getLoginUrl(type) + "&t=" + Date.now();
}
