import React from "react";
import once from "lodash/once";
import find from "lodash/find";
import reduce from "lodash/reduce";
import pickBy from "lodash/pickBy";
import locale from "js/main/locale";
const errorRegex = /^error #(\d+): (.*)$/;

// promise with lazy execution, results are only calculated if requested
exports.promise = function(func) {
  let value = null;
  let requested = false;
  let callbacks = [];

  // walk trough callbacks and resolve promise
  let fulfill = function(val) {
    value = val;
    callbacks.forEach((cb) => cb(val));
  };

  // return then function
  return function(callback) {
    if (!requested) {
      func(fulfill);
      requested = true;
    }

    if (value) {
      callback(value);
    } else {
      callbacks.push(callback);
    }
  }
};

/* see: https://github.com/JedWatson/classnames */
export const classnames = function classNames() {
	let classes = '';
	for (let i = 0; i < arguments.length; i++) {
		let arg = arguments[i];
		if (!arg){
       continue;
    }

		let type = typeof arg;
		if (type === 'string' || type === 'number') {
			classes += ' ' + arg;

		} else if (Array.isArray(arg)) {
			classes += ' ' + classNames.apply(null, arg);

		} else if (type === 'object') {
			for (let key in arg) {
				if (Object.hasOwnProperty.call(arg, key) && arg[key]) {
					classes += ' ' + key;
				}
			}
		}
	}
	return classes.substr(1);
}

// map error key to error object
const mapError = exports.mapError= function(key) {
  // use key as fallback message
  return {
    key,
    msg: locale.errors[key] || key,
  }
};

// convenience method
export const mapErrors = (keys) => keys.map(mapError);

// map server error response to error object
exports.parseErrorResponse= function(response) {
  let match = response.match(errorRegex);
  if (match) {
    // use server message as fallback
    return {
      key: match[1],
      msg: locale.errors[match[1]] || match[2]
    }
  }
  return null;
}

// Generic Error Message
exports.GenericError = {
  key: "generic",
  msg: locale.errors.generic
};

exports.formatNumber = function(value, delimiter = locale.delimiter) {
  return value.toString().replace(".", delimiter);
}

exports.parseNumber = function(string, delimiter = locale.delimiter) {
  return parseFloat(string.replace(delimiter, "."));
}

// format Number to localized Price String
exports.formatPrice = function(value, short = false, delimiter = locale.delimiter) {
  if (value === undefined || value === null)
    return "";

  // ensure type Number
  value = Number(value);
  const floored = value >= 0 ? Math.floor(value) : Math.ceil(value);

  // hide cents if they are < 1
  if (short && Math.abs(value - floored) < 0.005)
    return String(floored);

  return "{0}{1}{2}{3}".format(
    Math.sign(value) == -1 ? "-" : "",
    Math.abs(floored),
    delimiter,
    String(Math.abs(Math.round((value - floored) * 100)/100)).substring(2).padRight(2)
  );
}

// formats distance in meters
exports.formatDistance = function(distance) {
  if (distance < 1000) {
    return Math.round(distance / 10) * 10 + " m";
  } else {
    return Math.round(distance / 100) / 10 + " km";
  }
}


// TODO: format markdown to html text
exports.formatText = function(string) {
  let result = [];
  if(string){
    let parts = string.split("\n");
    parts.forEach((part, index) => result.push(<p key={index}>{part}</p>));
  }
  return result;
}

// build options array for persons
exports.getPersonOptions = function(min, current, remaining, identifier) {
  const options = [];
  for(let i = min; i <= current + remaining; i++){
    const ref = identifier[i === 1 ? 0 : 1];
    options.push(<option key={i} value={i}>{i + " " + ref}</option>);
  }
  return options;
}

// retrieve feature from featureGroup List
exports.getFeature = function(featureId) {
  for (let i = 0; i < init.featureGroups.length; i++) {
    const featureGroup= init.featureGroups[i];
    for (let j = 0; j < featureGroup.features.length; j++) {
      if (featureGroup.features[j].id === featureId) {
        return featureGroup.features[j];
      }
    }
  }
  return null;
}

{
  // cache a list of chargeable features
  const chargeableFeatures = [];
  const initFeatures = once(() => {
    init.featureGroups.forEach(function(featureGroup) {
      featureGroup.features.forEach(function(feature) {
        if (feature.chargeable)
          chargeableFeatures.push(feature.id);
      })
    })
  })

  exports.featureChargeable = function(featureId) {
    initFeatures();
    return chargeableFeatures.indexOf(featureId) !== -1;
  }
}

// get sum of selected features total prices
const getFeatureTotal = function(featureList, roomtype) {
  const prices = featureList.map(function(id) {
    const feature = find(roomtype.price.features, {"id": id});
    return feature ? feature.total : 0;
  });

  return reduce(prices, (total, value) => total + value, 0);
};

exports.getTotalPrice = function(featureList, roomtype, extensionOption) {
  const extensionPrice = extensionOption ? roomtype.price.extensionOption : 0;
  return roomtype.price.totalIncCleaning + getFeatureTotal(featureList, roomtype) +
    extensionPrice;
};

exports.getCurrencySymbol = function(currencyId) {
  for(let i = 0; i < init.currencies.length; i++) {
    const currency = init.currencies[i];
    if (currency.id == currencyId)
      return currency.symbol
  }
  return "";
}

exports.setMetaDescription = function(description) {
  // set meta description
  const meta = document.getElementsByTagName("meta");
  let oldDescription = "";
  for (let i = 0; i < meta.length; i++) {
      if (meta[i].name.toLowerCase() == "description") {
          oldDescription = meta[i].content;
          meta[i].content = description;
      }
  }
  return oldDescription;
}

exports.dateDifference = function(a, b) {
  return Math.round((a.getTime() - b.getTime()) / (24 * 3600 * 1000));
}

exports.daysInMonth = function(year, month) {
  return new Date(year, month + 1, 0).getDate();
}

// Generate four random hex digits.
const r4 = function() {
   return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

// Generate a pseudo-GUID by concatenating random hexadecimal.
exports.guid = function() {
   return (r4()+r4()+"-"+r4()+"-"+r4()+"-"+r4()+"-"+r4()+r4()+r4());
}

// for ids which just have to be unique in the open page
exports.simpleid = function() {
  let n = 0;
  return function() {
    return n++;
  }
}

/* Calculate Element Bounding Rect */
const scrollbarWidth = (function() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth + 1;
}());

exports.getRect = function(element) {
  element.style.display = "block";

  let left = 0,
    top = 0,
    width = element.offsetWidth,
    height = element.offsetHeight,
    current = element;

  while (current && !isNaN(current.offsetLeft) && !isNaN(current.offsetTop)) {
      left += current.offsetLeft - current.scrollLeft;
      top += current.offsetTop - current.scrollTop;
      current = current.offsetParent;
  }

  element.style.display = "";

  let right = window.innerWidth - left - width,
    bottom = window.innerHeight - top - height;

  // compensate for scrollbar
  right -= scrollbarWidth;

  return {top, right, bottom, left, width, height};
}

/* Walks up the DOM to find an element */
exports.findElement = function(element, condition) {
  if (!element)
    return null;

  let parent = element.parentElement;
  while (element && !condition(element) && element !== document.body && parent) {
    element = parent;
    parent = element.parentElement;
  }
  return element != document.body ? element : null;
}

/* convert null and undefined to zero */
exports.nullZero = (value) => value == null ? 0 : value;

const phoneRegExp = /[^\d+]/g;
exports.escapePhoneNumber = (string) => {
  let phone = string.replace(phoneRegExp, "");
  if (phone[0] == "0" && phone[1] == "0") {
    phone = "+" + phone.slice(2);
  } else if(phone[0] == "0") {
    phone = "+49" + phone.slice(1);
  }
  return phone;
}

// create copy of react props filtered by key
exports.filterProps = function(props, allowedKeys) {
  return pickBy(props, (value, key) => allowedKeys.indexOf(key) > -1);
}