import actions from "../main/actions";
import EventStore from "js/stores/EventStore";
import {serialize, deserialize} from "js/libs/url";
import log from "js/main/log";

const LANGUAGES = ["de", "en"];
const DEFAULT_LANGUAGE = "de";

class LocaleStore extends EventStore{
  constructor() {
    super();

    const preference = [deserialize(location.search).lang, localStorage.getItem("lang"), DEFAULT_LANGUAGE];
    for (let lang of preference) {
      const index = LANGUAGES.indexOf(lang)
      if (index > -1) {
        this.lang = lang;
        break;
      }
    }
    log(`Language is ${this.lang}`);

    // Store preference
    if (Modernizr.sessionstorage) {
      localStorage.setItem("lang", this.lang);
    }

    // Set initial url param
    this.initialLanguageChanged = false;
    if (window.history) {
      const [url, didChange] = this.replaceLangParameter(location.href)
      window.history.replaceState(null, "", url);
      this.initialLanguageChanged = didChange;
    }

    this.setLanguage = this.setLanguage.bind(this);
    this.listenTo(actions, "changeLanguage", this.setLanguage);
  }

  replaceLangParameter(url, lang=this.lang) {
    const split = url.split("?");
    let path = split[0];
    let query = {};
    let didChange = false;
    if (split.length > 1) {
      query = deserialize(split[1]);
    }
    if (query.lang != lang && lang != DEFAULT_LANGUAGE) {
      didChange = true;
    }
    query.lang = lang;

    // fix static page language change
    const match = path.match("^(.*/page/)(de|en)(/.*)$");
    if (match && match[2] !== lang && window.history) {
        path = match[1] + lang + match[3];
        log.info("static page with inconsistent language, replacing path", path, match);
    }
    return [`${path}?${serialize(query)}`, didChange];
  }

  setLanguage(lang, url=null) {
    if (LANGUAGES.indexOf(lang) == -1 || lang == this.lang)
      return;

    // Set lang parameter for new url
    [url,] = this.replaceLangParameter(url || location.href, lang);

    // Store language preference in sessionstorage
    if (Modernizr.sessionstorage) {
      localStorage.setItem("lang", lang);
    }

    // Reload page
    log("set language", lang, url);
    location.href = url;
  }
}

export default new LocaleStore();
