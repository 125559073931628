import config from "js/main/config";
import { init } from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const initSentry = () => {
  if (config.sentryKey) {
    init({
      dsn: config.sentryKey,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      allowUrls: ["www.urbanbnb.de", "dev.urbanbnb.de"],
      environment: config.environment,
    });
  }
};
