/**
 *  Load piwik and setup tracking code
 */
import log from "js/main/log";
import config from "js/main/config";
import wrapScript from "js/libs/wrapScript";

window._paq = [];
export const initPiwik = () => {
  window._paq = [];
  if (!config.piwikSiteId) {
    log.warn("No piwik site id configured");
    return;
  }
  const host = "https://www.basisstats.de";
  setTimeout(() => wrapScript(host + "/piwik.js"), 500);

  window._paq.push(["enableLinkTracking"]);
  window._paq.push(["setTrackerUrl", host + "/piwik.php"]);
  window._paq.push(["setSiteId", config.piwikSiteId]);
};

export const reportPiwik = function (action, ...args) {
  window._paq.push(Array.prototype.slice.call(arguments));
};
