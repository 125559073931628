import "less/views/widgets/calendar";
import partial from "lodash/partial";
import React from "react";
import createReactClass from 'create-react-class';
import locale from "js/main/locale";

let daysInMonth= function(year, month){
  return new Date(year, month + 1, 0).getDate();
}

const isSameDay= function(a, b){
  if(!a || !b)
    return false;
  return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear();
}

let CalendarView = createReactClass({
  displayName: 'CalendarView',

  dayTitles: locale.dayNames.map(function(name){
    return {
      name: name.substring(0, 2),
      properties: ["dayTitle"]
    }
  }),

  getInitialState: function(){
    this.today = new Date(init.serverTime);
    this.tomorrow = new Date(init.serverTime);
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    return {
      layout: "days",
      date: this.props.date || new Date(),
    }
  },

  componentWillReceiveProps: function(newProps){
    this.setState({date: newProps.date});
  },

  shouldComponentUpdate: function(nextProps, nextState){
    return JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
    JSON.stringify(this.state) !== JSON.stringify(nextState);
  },

  // methods for populating calendar with data
  getYears: function(date){
    let years = [];
    let decadeStart= date.getFullYear() - date.getFullYear() % 10;
    for(let i= decadeStart - 1; i < decadeStart + 11; i++){
      let props= ["year"];
      if(i === this.today.getFullYear()){
        props.push("current");
      }

      years.push({
        name: i,
        value: i,
        properties: props,
      });
    }
    return years;
  },

  getMonths: function(date){
    let months = [];
    let currentMonth;
    if(date.getFullYear() === this.today.getFullYear()){
      currentMonth = this.today.getMonth();
    }
    locale.monthNames.forEach(function(name, index){
      let props = ["month"];
      if(currentMonth === index){
        props.push("current");
      }
      months.push({
        name: name.substring(0,3),
        value: index,
        properties: props,
      })
    });
    return months;
  },

  getDays: function(date){
    let days= [];

    // days of previous month
    let prevDays= new Date(date.getFullYear(), date.getMonth(), 0).getDay();
    prevDays= prevDays === 0 ? 7 : prevDays;

    let prevDayCount= daysInMonth(date.getFullYear(), date.getMonth() - 1);
    for(let i= prevDays; i > 0; i--){
      days.push({
        name: (prevDayCount - i + 1),
        properties: ["prev", "day"]
      });
    }

    let dayCount = daysInMonth(date.getFullYear(), date.getMonth());
    let day= new Date(date);
    for(let i= 0; i < dayCount; i++){
      day.setDate(i + 1);
      let props= ["day"];

      if(day.isBefore(this.today) || (day.isBefore(this.tomorrow) && this.today.getHours() >= 18)){
        props.push("past");
      }

      days.push({
        name: day.getDate(),
        value: i + 1,
        properties: props
      });
    }

    // set selected property
    let selected= this.props.selected;
    if(selected && date.getFullYear() === selected.getFullYear() &&
       date.getMonth() === selected.getMonth()){
      days[prevDays + selected.getDate() - 1].properties.push("selected");
    }

    // days of next month
    let nextDays= 42 - prevDays - dayCount;
    for(let i= 0; i < nextDays; i++){
      days.push({
        name: (i + 1),
        properties: ["next", "day"]
      });
    }
    return days;
  },

  // left/right navigation
  nextDate: function(direction){
    let date= new Date(this.state.date);
    switch(this.state.layout){
      case "years":
        date.setFullYear(date.getFullYear() + direction * 10);
        break;
      case "months":
        date.setFullYear(date.getFullYear() + direction);
        break;
      case "days":
        date.setDate(1);
        date.setMonth(date.getMonth() + direction + 1);
        date.setDate(0);
    }
    this.setState({date});
  },

  handleClick: function(item){
    let properties= item.properties;
    if(properties.indexOf("next") > -1){
      this.nextDate(1);
    }else if(properties.indexOf("prev") > -1){
      this.nextDate(-1);

    }else if(properties.indexOf("year") > -1){
      this.state.date.setYear(item.name);
      this.setState({layout: "months"});

    }else if(properties.indexOf("month") > -1){
      this.state.date.setMonth(item.value);
      this.setState({layout: "days"});

    // click on already selected day
    // }else if(properties.indexOf("day") > -1 && properties.indexOf("selected") > -1){
      // this.setState({selected: undefined});

    }else if(properties.indexOf("day") > -1){
      let selected= new Date(this.state.date);
      selected.setDate(item.value);
      this.props.onSelect(selected);
    }
  },

  handleTitleClick: function(){
    switch(this.state.layout){
      case "days":
        this.setState({layout: "months"});
        break;

      case "months":
        // this.setState({layout: "years"});
    }
  },

  render: function(){
    let date= this.state.date;
    let items= [];
    let title= "";
    let f= (identifier, item) => <div
      key= {identifier + item.name + String(item.value || 0)}
      onClick= {this.handleClick.bind(this, item)} title=""
      className= {item.properties.join(" ")}>
      {item.name}
    </div>;

    switch(this.state.layout){
      case "years":
        {
          let decadeStart= date.getFullYear() - date.getFullYear() % 10;
          title= decadeStart + "-" + (decadeStart + 9);
          items= this.getYears(date).map(partial(f, "year"));
        }
        break;
      case "months":
        title= date.getFullYear();
        items= this.getMonths(date).map(partial(f, "month"));
        break;
      case "days":
        title= locale.monthNames[date.getMonth()] + " " + date.getFullYear();
        items= this.dayTitles.map(partial(f, "dayTitle"));
        items.push.apply(items, this.getDays(date).map(partial(f, "day")));
    }

    return <div className="calendar" ref="calendar">
      <div className="head">
        <div onClick={this.nextDate.bind(this, -1)} className="prev icon icon-left" title={locale.previous}>
        &lt;</div><div onClick={this.handleTitleClick} className="title" title="">{title}
        </div><div onClick={this.nextDate.bind(this, 1)} className="next icon icon-right" title={locale.next}>&gt;</div>
      </div>
      <div className="dateContainer clearfix">
        {items}
      </div>
    </div>
  },
})

export default CalendarView;
