exports.env = "prod";
exports.logLevel = 3;
exports.url = function(path) { return "/v1/" + path };
exports.imageUrl = function(path) { return path };
exports.blogImageUrl = function(path) { return "https://www.nd-bed-breakfast.de" + path };
exports.mapboxAPIKey = "pk.eyJ1IjoiYXJpbWFubyIsImEiOiI5T3dsd2RJIn0.v5yiEMhru7emkr7MgqdSnQ";
exports.googleAPIKey = "AIzaSyBLyoe-N8n6tXpvOPWnTp8Rzf93v30hg5s";
exports.analyticsAPIKey = "UA-76853366-1";
exports.piwikSiteId = 83;
exports.sentryKey = "https://68d618ea278361bbc48c3ccdf8e62f72@sentry.basehosts.de/8";
